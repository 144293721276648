const heic2any = require("heic2any");
const Tiff = require('tiff.js');
$(document).ready(function () {
    const URL = $("#my_domain").val();
    const ERROR_MSG = "エラー！　処理を完了できませんでした\n時間おいて再度実行しても、このエラーが修正されない場合は\nお手数ですがサポートチームにお問い合わせください"
    const MAIL_PATTERN = /^[a-zA-Z0-9_+-]+(.[a-zA-Z0-9_+-]+)*@([a-zA-Z0-9][a-zA-Z0-9-]*[a-zA-Z0-9]*\.)+[a-zA-Z]{2,}$/;
    const PASSWORD_PATTERN = /([0-9].*[a-zA-Z]|[a-zA-Z].*[0-9])/;
    const Device = require('@twilio/voice-sdk').Device;
    //*********************************************************************
    // 初期処理系
    //*********************************************************************

    // トークルームへのリンクは、全てターボリンクスを無効にする
    $('a[href*="/chat_room/reservation/"]').attr('data-turbolinks', 'false');

    //############################################################
    // フォーカス
    //############################################################
    if (location.pathname == "/s/new" || location.pathname == "/s/login" || location.pathname == "/") {
        $('input[type=\'text\']:visible').eq(0).focus();
    }
    ;

    //############################################################
    // エンター押下
    //############################################################
    $(document).keypress(function (e) {
        if (e.keyCode == 13 && e.target.nodeName !== "TEXTAREA") {
            $('.enter').click();
            return false;
        }
    });

    //############################################################
    // フォーム読み込み時の処理（変更ある時のみ、ボタン押下が可能）
    //############################################################
    if ((location.pathname == "/s/salon_edit" || location.pathname == "/s/account") && location.search != "?b=1") {
        let form_str;

        function check_form_edit() {
            var form_str2;
            $("input,select,textarea").each(function (i, e) {
                if ($(e).attr("id") == "form_str") {
                    return;
                }
                form_str2 += $(e).attr("id") + ":";
                if ($(e).attr("type") == "radio") {
                    if ($(e).prop('checked')) {
                        form_str2 += "1"
                    } else {
                        form_str2 += "0"
                    }
                } else {
                    form_str2 += $(e).val();
                }
            });
            if ($("#form_str").val() != form_str2) {
                $(".disabled").fadeOut(0);
                $(".enabled").fadeIn(100);
            } else {
                $(".enabled").fadeOut(0);
                $(".disabled").fadeIn(100);
            }
        }

        $("input,select,textarea").each(function (i, e) {

            if ($(e).attr("id") == "form_str") {
                return;
            }
            form_str += $(e).attr("id") + ":";
            if ($(e).attr("type") == "radio") {
                if ($(e).prop('checked')) {
                    form_str += "1"
                } else {
                    form_str += "0"
                }
            } else {
                form_str += $(e).val();
            }
        });
        $("#form_str").val(form_str);
        $("input,select,textarea").on("change", function () {
            check_form_edit();
        })
        $("input,select,textarea").on("keyup", function () {
            check_form_edit();
        })
    }
    ;

    //############################################################
    // スペーストリム
    //############################################################
    $("input,textarea").on("blur", function () {
        $("input,textarea").each(function (i, e) {
            $(e).val($.trim($(e).val()));
        })
        $("input[type='text']").trigger("keyup");
        $("input[type='email']").trigger("keyup");
        $("textarea").trigger("keyup");
    })

    //*********************************************************************
    // 料金プラン 関連処理
    //*********************************************************************
    //############################################################
    // 料金プランコピーフォームの表示、非表示
    //############################################################
    $(".open_copy_form").on("click", function () {
        $(".copy_form").fadeIn(360);
        $(".open_copy_form").fadeOut(0);
        $(".close_copy_form").fadeIn(360).css("display", "inline-block");
    })
    $(".close_copy_form").on("click", function () {
        $(".copy_form").fadeOut(0);
        $(".close_copy_form").fadeOut(0);
        $(".open_copy_form").fadeIn(360).css("display", "inline-block");
        ;
    })
    //############################################################
    // 料金プランコピーフォームの表示、非表示
    //############################################################
    $("#copy_service").on("click", function () {
        if (!confirm("この料金プラン情報をコピーしますか？\n【注意！】このサロンの料金プラン情報は上書きされ、以前のデータは削除されます。")) {
            return;
        }
        $(".copy_service").css('pointer-events', 'none');
        $.ajax({
            url: URL + "/s/copy_service",
            type: "POST",
            data: {
                copy_salon_id: $("#copy_salon_id").val(),
                salon_id: $("#salon_id").val()
            },
            dataType: "json"
        })
            .done(function (data) {
                if (data.ret == 0) {
                    alert("料金プランをコピーしました");
                    location.reload();
                } else if (data.ret == 1) {
                    alert("エラー：コピー元のサロンに料金プランがありません");
                    $(".copy_service").css('pointer-events', 'auto');
                }
            })
            .fail(function () {
                alert(ERROR_MSG);
                $(".copy_service").css('pointer-events', 'auto');
            })
    })

    //############################################################
    // 料金プラン作成＆更新
    //############################################################
    $("#update_service").on("click", function () {
        let btn = $("#update_service");
        let time = $("#time").val();
        if ($("#name").val() == "") {
            alert("料金プラン名を入力してください");
            return;
        }
        if ($("#price").val() == "") {
            alert("料金を入力してください");
            return;
        }
        if ( $('#service-type').val() == 'is-mail' ) {
            if ( time < 1 ){
                alert("返信期限は1日以上を設定してください");
                return;
            }else if( time > 28 ){
                alert("返信期限は28日以内で設定してください");
                return;
            }else if( time.trim() == ""){
                alert("返信期限を入力してください");
                return;
            }
        }else if ( ($('#service-type').val() == 'is-chat') || ($('#service-type').val() == 'is-instant-telephone') ) {
            // 今すぐ電話鑑定、今すぐテキストチャット鑑定なら時間は10に固定する。
            $("#time").val(10);
        }
        else{
            let text = $('#type-title').text();
            if ( time < 5 ){
                alert(text + "は5分以上を設定してください");
                return;
            }else if( time.trim() == ""){
                alert(text + "を入力してください");
                return;
            }
        }

        let files = document.getElementById("upfile").files;
        let oFile = files[0];
        let data_url_scheme = ''
        let file_name = ''
        if (oFile != undefined) {
            if (31457280 < oFile.size) {
                alert("登録できませんでした　\n※ アップロードできる写真の最大サイズは30MBです\n  ファイルサイズを変更して、再度写真をセットしてください");
                return;
            }
            btn.css('pointer-events', 'none');
            let reader = new FileReader();
            reader.readAsDataURL(oFile);
            reader.onload = function () {
                // データURLスキームを取得
                data_url_scheme = reader.result;
                file_name = encodeURIComponent(oFile.name);
                update_service_ajax(data_url_scheme, file_name);
            }
        }else{
            update_service_ajax();
        }
    });

    function update_service_ajax(data_url_scheme="", file_name=""){
        loadingModal('show');
        let btn = $("#update_service");
        let time = $("#time").val();
        let path = "";
        let msg_no = "";
        if ($("#id").val() == "" || $("#id").val() == undefined || $("#id").val() == "undefined") {
            path = "/s/create_service"
            msg_no = 1;
        } else {
            path = "/s/update_service"
            msg_no = 2;
        }

        let serviceType = $('#service-type').val();

        let paymentVal = 0;
        if ($('#service-payment').length){ paymentVal = $('#service-payment').prop('checked') ? 1 : 0; }

        // オンライン鑑定なら、zoom連携の項目をチェックする。
        let zoomVal = 0;
        if ($('#service-zoom').length){
            if ( serviceType === 'is-online' ){
                zoomVal = $('#service-zoom').prop('checked') ? 1 : 0;
            }
        }
        btn.css('pointer-events', 'none');
        $.ajax({
            url: URL + path,
            type: "POST",
            data: {
                id: $("#id").val(),
                salon_id: $("#salon_id").val(),
                name: $("#name").val(),
                price: $("#price").val(),
                isOffline: serviceType==='is-offline' ? 1:0,
                isMail: serviceType==='is-mail' ? 1:0,
                isTelephone: serviceType==='is-telephone' ? 1:0,
                isInstantTelephone: serviceType==='is-instant-telephone' ? 1:0,
                isChat: serviceType==='is-chat' ? 1:0,
                time: time,
                description: $("#description").val(),
                priority: $("#priority").val(),
                release: $("input[name='release']:checked").val(),
                payment: paymentVal,
                zoom: zoomVal,
                img: data_url_scheme,
                file_name: file_name,
                delete_flg: $("#delete_flg").val(),
            },
            dataType: "json"
        })
            .done(function (data) {
                loadingModal('hide');
                switch (data.ret) {
                    case 'success':
                        location.href = "/s/salon_services?id=" + $("#salon_id").val() + "&m=" + msg_no;
                        break;
                    case 'extension':
                        alert("画像がアップロードできませんでした\n登録可能な拡張子は[jpg][jpeg][png]のみとなります");
                        btn.css('pointer-events', 'auto');
                        break;
                    case 'size':
                        alert("画像がアップロードできませんでした　\n※ アップロードできる画像の最大サイズは1MBです\n  ファイルサイズを変更して、再度画像をセットしてください");
                        btn.css('pointer-events', 'auto');
                        break;
                    case 'duplicate':
                        alert("重複：同じサービス名が登録されていますので、登録できません");
                        btn.css('pointer-events', 'auto');
                    default:
                        alert(ERROR_MSG);
                        btn.css('pointer-events', 'auto');
                        break;
                }
            })
            .fail(function () {
                loadingModal('hide');
                alert(ERROR_MSG);
                $("#update_service").css('pointer-events', 'auto');
            })
    }

    $(".service_form #time").on('input', function(e){
        ValidNumberOnly(e);
    });

    // サービスタイプ切り替え時に、タイプに応じてUI動的変更
    if ( $('#update_service').length ) {
        $('#service-type').on('change', function() {
            // メール鑑定
            if ( $(this).val() === 'is-mail' ) {
                if ($('#service-payment').length){ $('#service-payment').prop('disabled',false); }
                $('#type-title').text('返信期限');
                $('#type-desc').text('日以内に返信');
                $('#type-price').css('display', 'none');
                // zoom連携項目を非表示
                if ($('#service-zoom-wrapper').length) {
                    $('#service-zoom-wrapper').css('display', 'none');
                }
                $('.tr-service-payment').css('display', 'table-row');
                $('.tr-service-time').css('display', 'table-row');

            }else if( $(this).val() === 'is-instant-telephone' ) {
                // 今すぐ電話鑑定
                // * 事前決済を必須にして非表示に。
                // * 時間の設定を非表示に。
                $('#service-payment').prop('checked', true);
                $('#service-payment').prop('disabled', true);
                $('.tr-service-payment').css('display', 'none');
                $('.tr-service-time').css('display', 'none');

            }else if ( $(this).val() === 'is-chat' ) {
                // 今すぐテキストチャット鑑定
                $('#service-payment').prop('checked', true);
                $('#service-payment').prop('disabled', true);
                $('.tr-service-payment').css('display', 'none');
                $('.tr-service-time').css('display', 'none');

            }else{
                // それ以外
                if ($('#service-payment').length){ $('#service-payment').prop('disabled',false); }
                $('#type-title').text('所要時間');
                $('#type-desc').text('分');
                $('#type-price').css('display', 'none');
                $('.tr-service-payment').css('display', 'table-row');
                $('.tr-service-time').css('display', 'table-row');
                // オンライン鑑定なら、zoom連携項目を表示。
                if ( $(this).val() === 'is-online' ) {
                    if ($('#service-zoom-wrapper').length) { $('#service-zoom-wrapper').css('display','table-row'); }
                    // それ以外なら、zoom項目非表示に。
                }else{
                    if ($('#service-zoom-wrapper').length) { $('#service-zoom-wrapper').css('display','none'); }
                }
            }
        });
    }


    //############################################################
    // 料金プラン削除
    //############################################################
    $("#delete_service").on("click", function () {
        if (!confirm("この料金プラン情報を削除しますか？\n【注意！】削除した情報は元に戻せません")) {
            return;
        }
        $("#delete_service").css('pointer-events', 'none');
        $.ajax({
            url: URL + "/s/delete_service",
            type: "POST",
            data: {
                id: $("#id").val()
            },
            dataType: "json"
        })
            .done(function (data) {
                location.href = "/s/salon_services?id=" + $("#salon_id").val() + "&m=3";
            })
            .fail(function () {
                alert(ERROR_MSG);
                $("#delete_service").css('pointer-events', 'auto');
            })
    })

    //*********************************************************************
    // カウンセラー 関連処理
    //*********************************************************************
    //############################################################
    // カウンセラー一覧のボタンクリックイベント
    // counselors_all
    //############################################################

    // カウンセラーのスケジュール管理ボタン
    $('.button-counselor-schedule').each(function(i,elm){
        $(elm).on('click', function(ev){
            ev.preventDefault();
            let cid = $(this).attr('cid');
            let s1id = $(this).attr('s1id'); let s1name = $(this).attr('s1name'); let s1reserve = $(this).attr('s1r');
            let s2id = $(this).attr('s2id'); let s2name = $(this).attr('s2name'); let s2reserve = $(this).attr('s2r');
            if (s1id && s2id && s1reserve === "1" && s2reserve === "1" ){
                let names = [$(this).attr('s1name'), $(this).attr('s2name')];
                let urls  = [
                    '/s/counselor_schedule?id=' + $(this).attr('cid') + '&salon_id=' + $(this).attr('s1id'),
                    '/s/counselor_schedule?id=' + $(this).attr('cid') + '&salon_id=' + $(this).attr('s2id')
                ];
                selectSalonModal('スケジュール管理', names, urls);
            } else if (s1id && s1reserve === "1") {
                location.href = '/s/counselor_schedule?id=' + cid + '&salon_id=' + s1id;
            } else if (s2id && s2reserve === "1") {
                location.href = '/s/counselor_schedule?id=' + cid + '&salon_id=' + s2id;
            }
        });
    });

    // カウンセラーのメニュー設定ボタン
    $('.button-counselor-service').each(function(i,elm){
        $(elm).on('click', function(ev){
            ev.preventDefault();
            let cid = $(this).attr('cid');
            let s1id = $(this).attr('s1id'); let s1name = $(this).attr('s1name'); let s1reserve = $(this).attr('s1r');
            let s2id = $(this).attr('s2id'); let s2name = $(this).attr('s2name'); let s2reserve = $(this).attr('s2r');
            if (s1id && s2id && s1reserve === "1" && s2reserve === "1" ){
                let names = [$(this).attr('s1name'), $(this).attr('s2name')];
                let urls  = [
                    '/s/counselor_service?id=' + $(this).attr('cid') + '&salon_id=' + $(this).attr('s1id'),
                    '/s/counselor_service?id=' + $(this).attr('cid') + '&salon_id=' + $(this).attr('s2id')
                ];
                selectSalonModal('受付メニューの設定', names, urls);
            } else if (s1id && s1reserve === "1") {
                location.href = '/s/counselor_service?id=' + cid + '&salon_id=' + s1id;
            } else if (s2id && s2reserve === "1") {
                location.href = '/s/counselor_service?id=' + cid + '&salon_id=' + s2id;
            }
        });
    });

    // カウンセラーの予約カレンダーボタン
    $('.button-reservation-calendar').each(function(i,elm){
        $(elm).on('click', function(ev){
            ev.preventDefault();
            let cid = $(this).attr('cid');
            let s1id = $(this).attr('s1id'); let s1name = $(this).attr('s1name'); let s1reserve = $(this).attr('s1r');
            let s2id = $(this).attr('s2id'); let s2name = $(this).attr('s2name'); let s2reserve = $(this).attr('s2r');
            if (s1id && s2id && s1reserve === "1" && s2reserve === "1" ){
                let names = [$(this).attr('s1name'), $(this).attr('s2name')];
                let urls  = [
                    '/s/reservation_calendar?counselor_id=' + $(this).attr('cid') + '&salon_id=' + $(this).attr('s1id'),
                    '/s/reservation_calendar?counselor_id=' + $(this).attr('cid') + '&salon_id=' + $(this).attr('s2id')
                ];
                selectSalonModal('予約カレンダー確認', names, urls);
            } else if (s1id && s1reserve === "1") {
                location.href = '/s/reservation_calendar?counselor_id=' + cid + '&salon_id=' + s1id;
            } else if (s2id && s2reserve === "1") {
                location.href = '/s/reservation_calendar?counselor_id=' + cid + '&salon_id=' + s2id;
            }
        });
    });

    // 関数_モーダルでリンク生成
    function selectSalonModal(text, names, urls) {
        $('#modal-background').addClass('is-show');
        $('#link-text').text(text);
        $('#modal-body').html('');
        $(names).each(function(i,elm){
            let link = $("<a>", {href: urls[i]}).appendTo('#modal-body');
            link.on('click', function(e) { $('#modal-background').removeClass('is-show'); })
            $('<div>', { class:'select-salon-button', text: names[i] }).appendTo(link);
        });
        let cancelButton = $('<div>', { class:'select-salon-cancel', text: 'キャンセル'}).appendTo('#modal-body');
        cancelButton.on('click', function(e) { $('#modal-background').removeClass('is-show'); })

    }

    //############################################################
    // カウンセラー個別のログイン情報表示
    //############################################################
    $(".display_login_info").on("click", function () {
        $('body').append('<div id=\'lightbox_back\'></div>');
        let bg = $("#lightbox_back")
        bg.ready(function () {
            bg.css({
                'background-color': 'black',
                'opacity': '0.5',
                'position': 'fixed',
                'top': '0',
                'left': '0',
                'width': $(window).width(),
                'height': $(window).height(),
                'z-index': '2000000',
                'display': 'none',
                'display': 'none',
                'min-height': '100vh', /* カスタムプロパティ未対応ブラウザ用のフォールバック */
                'min-height': 'calc(var(--vh, 1vh) * 100)'
            });
            bg.fadeIn(200);
            $(window).resize(function () {
                bg.css({
                    'width': $(window).width(),
                    'height': $(window).height()
                });
                let vh = window.innerHeight * 0.01;
                document.documentElement.style.setProperty('--vh', `${vh}px`);
            });
            $(window).scroll(function () {
                bg.css({
                    'width': $(window).width(),
                    'height': $(window).height()
                });
                let vh = window.innerHeight * 0.01;
                document.documentElement.style.setProperty('--vh', `${vh}px`);
            });
            bg.on("click", function () {
                bg.remove();
                $("#counselor-login-info").fadeOut(100);
            });
        })
        $("#password1").val("");
        $("#password2").val("");
        $("#login-info-name").html($(this).data("name"))
        $("#login-info-id").html($(this).data("val"))
        $("#counselor-login-info").fadeIn(200);
        $(".close").on("click", function () {
            $("#lightbox_back").remove();
            $("#counselor-login-info").fadeOut(100);
        });
    });

    //############################################################
    // カウンセラー
    //############################################################
    $("#update_counselor_password").on("click", function () {
        if ($("#password1").val()=="") {
            alert("パスワードを入力してください");
            return false;
        }
        if (!$("#password1").val().match(PASSWORD_PATTERN)) {
            alert("パスワードは英数字混在で入力してください");
            return false;
        }
        if ($("#password1").val().length < 8) {
            alert("パスワードは8文字以上で入力してください");
            return false;
        }
        if ($("#password2").val() == "") {
            alert("パスワード(再確認)を入力してください");
            return false;
        }
        if ($("#password1").val() != $("#password2").val()) {
            alert("パスワード(再確認)の入力が正しくありません");
            return false;
        }
        if (!confirm("パスワードを変更しますか？")) {
            return;
        }
        $("#update_counselor_password").css('pointer-events', 'none');
        $.ajax({
            url: URL + "/s/update_counselor_password",
            type: "POST",
            data: {
                cid: $("#login-info-id").html(),
                password: $("#password1").val()
            },
            dataType: "json"
        })
            .done(function (data) {
                if (data.ret == 0) {
                    alert("パスワードが正常に変更されました");
                    $("#update_counselor_password").css('pointer-events', 'auto');
                    $("#lightbox_back").remove();
                    $("#counselor-login-info").fadeOut(100);
                }
            })
            .fail(function () {
                alert(ERROR_MSG);
                $("#update_counselor_password").css('pointer-events', 'auto');
            })
    });

    //############################################################
    // カウンセラー作成＆更新
    //############################################################
    $("#confirm_counselor").on("click", function () {
        let m_file_name = "";
        if ($("#name").val() == "") {
            alert("名前を入力してください");
            return;
        }
        if ($("#description").val() == "") {
            alert("紹介文を入力してください");
            return;
        }
        if ($("#salon").val() == $("#salon2").val()) {
            alert("サロンの選択に誤りがあります\n（※ 兼任しているサロンの重複）");
            return;
        }
        if ($("#keyword_flg").val() == "1") {
            if (!salon_keywords_validation()) {
                return false;
            }
        }

        let path = "/s/set_data"
        $("#confirm_counselor").css('pointer-events', 'none');
        var files = document.getElementById("upfile").files;
        var oFile = files[0];

        if (oFile != undefined) {
            // 写真あり
            m_file_name = oFile.name;

            if (1048576 < oFile.size) {
                alert("登録できませんでした　\n※ アップロードできる写真の最大サイズは1MBです\n  ファイルサイズを変更して、再度写真をセットしてください");
                $("#confirm_counselor").css('pointer-events', 'auto');
                return;
            }

            var reader = new FileReader();
            reader.readAsDataURL(oFile);
            reader.onload = function () {
                // データURLスキームを取得
                var data_url_scheme = reader.result;
                var file_name = encodeURIComponent(m_file_name);
                update_counselor_ajax(data_url_scheme, file_name, path);

            }
        } else {
            // 写真なし
            update_counselor_ajax("", "", path);
        }
    });

    //############################################################
    // カウンセラー作成＆更新 ajax
    //############################################################
    function update_counselor_ajax(data_url_scheme, file_name, path) {
        let release;
        if (location.pathname == "/s/counselor_form") {
            release = $("input[name='release']:checked").val();
        } else if (location.pathname == "/s/counselor_confirm") {
            release = $("#release").val();
        }

        $.ajax({
            url: URL + path,
            type: "POST",
            data: {
                func: "counselor",
                id: $("#id").val(),
                name: $("#name").val(),
                title: $("#title").val(),
                salon: $("#salon").val(),
                salon2: $("#salon2").val(),
                description: $("#description").val(),
                method: $("#method").val(),
                charge: $("#charge").val(),
                consultation: $("#consultation").val(),
                message: $("#message").val(),
                important_notes: $("#important_notes").val(),
                keyword_flg: $("#keyword_flg").val(),
                delete_flg: $("#delete_flg").val(),
                priority: $("#priority").val(),
                release: release,
                img: data_url_scheme,
                file_name: file_name,
                img_bin: $("#img_bin").val(),
                mail: $("#mail").val(),
                keyword0: $("#keyword0").val(),
                keyword1: $("#keyword1").val(),
                keyword2: $("#keyword2").val(),
                keyword3: $("#keyword3").val(),
                keyword4: $("#keyword4").val(),
                keyword5: $("#keyword5").val(),
                keyword6: $("#keyword6").val(),
                keyword7: $("#keyword7").val(),
                keyword8: $("#keyword8").val(),
                keyword9: $("#keyword9").val(),
                keyword10: $("#keyword10").val(),
                keyword11: $("#keyword11").val(),
                keyword12: $("#keyword12").val(),
                keyword13: $("#keyword13").val(),
                keyword14: $("#keyword14").val(),
                keyword15: $("#keyword15").val(),
                keyword16: $("#keyword16").val(),
                keyword17: $("#keyword17").val(),
                keyword18: $("#keyword18").val(),
                keyword19: $("#keyword19").val()
            },
            dataType: "json"
        })
            .done(function (data) {
                if (data.ret == 0) {
                    let href = "";
                    if (location.pathname == "/s/counselor_form") {
                        href = "/s/counselor_confirm?id=" + $("#id").val() + "&salon_id=" + $("#salon_id").val();
                    } else if (location.pathname == "/s/counselor_confirm") {
                        if ($("#salon_id").val() == "") {
                            href = "/s/counselors_all?m=" + data.msg;
                        } else {
                            href = "/s/counselors?id=" + $("#salon_id").val() + "&m=" + data.msg;
                        }
                    }
                    location.href = href;
                } else if (data.ret == 1) {
                    alert("アップロードできませんでした\n登録可能な拡張子は[jpg][jpeg][png]のみとなります");
                    $("#confirm_counselor").css('pointer-events', 'auto');
                } else if (data.ret == 2) {
                    alert("アップロードできませんでした　\n※ アップロードできる画像の最大サイズは1MBです\n  ファイルサイズを変更して、再度画像をセットしてください");
                    $("#confirm_counselor").css('pointer-events', 'auto');
                }
            })
            .fail(function () {
                alert(ERROR_MSG);
                $("#confirm_counselor").css('pointer-events', 'auto');
            })
    };

    //############################################################
    // カウンセラー作成＆更新
    //############################################################
    $("#update_counselor").on("click", function () {
        let path;
        if ($("#id").val() == "" || $("#id").val() == undefined || $("#id").val() == "undefined") {
            path = "/s/create_counselor"
            if (!confirm("カウンセラー情報を登録しますか？")) {
                return;
            }
        } else {
            path = "/s/update_counselor"
            if (!confirm("カウンセラー情報を変更しますか？")) {
                return;
            }
        }
        update_counselor_ajax($("#img").val(), $("#file_name").val(), path);
    })

    //############################################################
    // カウンセラー掲載順位変更（兼任の場合）
    //############################################################
    $("#update_concurrent").on("click", function () {
        if (!confirm("掲載順位を変更しますか？")) {
            return;
        }
        $("#update_concurrent").css('pointer-events', 'none');
        $.ajax({
            url: URL + "/s/update_concurrent",
            type: "POST",
            data: {
                id: $("#id").val(),
                priority2: $("#priority2").val()
            },
            dataType: "json"
        })
            .done(function (data) {
                location.href = "/s/counselors?id=" + $("#salon_id").val() + "&m=3";
            })
            .fail(function () {
                alert(ERROR_MSG);
                $("#update_concurrent").css('pointer-events', 'auto');
            })
    })

    //############################################################
    // カウンセラー削除
    //############################################################
    $("#delete_counselor").on("click", function () {
        if (!confirm("このカウンセラー情報を削除しますか？\n【注意！】削除した情報は元に戻せません")) {
            return;
        }
        $("#delete_counselor").css('pointer-events', 'none');
        $.ajax({
            url: URL + "/s/delete_counselor",
            type: "POST",
            data: {
                id: $("#id").val()
            },
            dataType: "json"
        })
            .done(function (data) {
                if ($("#salon_id").val() == "") {
                    location.href = "/s/counselors_all?m=4";
                } else {
                    location.href = "/s/counselors?id=" + $("#salon_id").val() + "&m=4";
                }
            })
            .fail(function () {
                alert(ERROR_MSG);
                $("#delete_counselor").css('pointer-events', 'auto');
            })
    })

    //############################################################
    // カウンセラー写真削除
    //############################################################
    $(".no_preview").on("click", function () {
        alert("プレビューをするためには、カウンセラーの所属を設定してください");
    });

    //############################################################
    // カウンセラー　キーワードフォーム表示切り替え
    //############################################################
    $("#keyword_flg").on("change", function () {
        if ($(this).val() == "1") {
            $(".counselor_keywords").fadeIn(360);
        } else {
            $(".counselor_keywords").fadeOut(200);
        }
    });

    //############################################################
    // カウンセラー写真削除
    //############################################################
    $("#delete_image").on("click", function () {
        $("#upfile").val("")
        $("#preview_area").fadeOut(0);
        $("#delete_flg").val("1");
        $("#img_bin").val("");
    });

    //############################################################
    // 掲載順位のblur処理
    //############################################################
    $("#priority").on("blur", function () {
        $(this).val($(this).val().replace(/[^0-9]/g, ''));
    })

    //############################################################
    // カウンセラー兼任設定の表示切り替え
    //############################################################
    $("#concurrent_msg1").on("click", function () {
        $("#concurrent_msg1").fadeOut(0);
        $("#concurrent_msg2").fadeIn(100);
        $("#salon2").fadeIn(100);
    })
    $("#concurrent_msg2-1,#concurrent_msg3-1").on("click", function () {
        $("#concurrent_msg2,#concurrent_msg3").fadeOut(0);
        $("#salon2").fadeOut(0);
        $("#concurrent_msg1").fadeIn(100);
        $('#salon2').val("");
    })
    //*********************************************************************
    // サロン検索条件 関連処理
    //*********************************************************************
    $("#update_keywords").on("click", function () {
        $("#update_keywords").css('pointer-events', 'none');
        if (!salon_keywords_validation()) {
            $("#update_keywords").css('pointer-events', 'auto');
            return false;
        }
        ;
        if (!confirm("検索条件の変更を登録しますか？")) {
            $("#update_keywords").css('pointer-events', 'auto');
            return;
        }
        $.ajax({
            url: URL + "/s/update_keywords",
            type: "POST",
            data: {
                id: $("#id").val(),
                keyword0: $("#keyword0").val(),
                keyword1: $("#keyword1").val(),
                keyword2: $("#keyword2").val(),
                keyword3: $("#keyword3").val(),
                keyword4: $("#keyword4").val(),
                keyword5: $("#keyword5").val(),
                keyword6: $("#keyword6").val(),
                keyword7: $("#keyword7").val(),
                keyword8: $("#keyword8").val(),
                keyword9: $("#keyword9").val(),
                keyword10: $("#keyword10").val(),
                keyword11: $("#keyword11").val(),
                keyword12: $("#keyword12").val(),
                keyword13: $("#keyword13").val(),
                keyword14: $("#keyword14").val(),
                keyword15: $("#keyword15").val(),
                keyword16: $("#keyword16").val(),
                keyword17: $("#keyword17").val(),
                keyword18: $("#keyword18").val(),
                keyword19: $("#keyword19").val()
            },
            dataType: "json"
        })
            .done(function (data) {
                alert("検索条件の変更が完了しました");
                location.href = "/s/salon_manage?id=" + $("#id").val();
            })
            .fail(function () {
                alert(ERROR_MSG);
                $("#update_keywords").css('pointer-events', 'auto');
            })
    });
    //*********************************************************************
    // サロン画像管理 関連処理
    //*********************************************************************
    //############################################################
    // ライトボックス処理
    //############################################################
    $(".lightbox").on("click", function () {
        // 背景
        $('body').append('<div id=\'lightbox_back\'></div>');
        var bg = $("#lightbox_back")
        bg.ready(function () {
            bg.css({
                'background-color': 'black',
                'opacity': '0.5',
                'position': 'fixed',
                'top': '0',
                'left': '0',
                'width': $(window).width(),
                'height': $(window).height(),
                'z-index': '2000000',
                'display': 'none'
            });
            bg.fadeIn(200);
        })
        $(window).resize(function () {
            bg.css({
                'width': $(window).width(),
                'height': $(window).height()
            });
        });
        // 画像
        $('.salon_img').prepend("<img id='lightbox_img' src='" + $(this).attr("src") + "'>");
        var lightbox_img = $("#lightbox_img")
        lightbox_img.ready(function () {
            lightbox_img.css({
                'position': 'fixed',
                'top': '120',
                'left': $(window).width() / 2 - 180,
                'z-index': '3000000',
                'width': '360',
                'border': '1px solid #d0d0d0'
            });
            lightbox_img.fadeIn(350);
            bg.on("click", function () {
                bg.remove();
                lightbox_img.remove();
            });
        })
    });

    //############################################################
    // 写真フォームを表示／非表示
    //############################################################
    // アップロードフォーム
    $("#show_upload_form").on("click", function () {
        $("#show_upload_form").fadeOut(0);
        $("#show_priority_form").fadeOut(0);
        $("#show_delete_form").fadeOut(0);
        $(".upload_form").fadeIn(200);
        var element = document.documentElement;
        var bottom = element.scrollHeight - element.clientHeight;
        window.scroll(0, bottom);
    })
    $("#hide_upload_form").on("click", function () {
        $(".upload_form").fadeOut(0);
        $("#show_upload_form").fadeIn(200);
        $("#show_priority_form").fadeIn(200);
        $("#show_delete_form").fadeIn(200);
    })
    // 優先順位フォーム
    $("#show_priority_form").on("click", function () {
        $("#show_upload_form").fadeOut(0);
        $("#show_priority_form").fadeOut(0);
        $("#show_delete_form").fadeOut(0);
        $(".priority").fadeIn(200);
        $(".priority_msg").fadeIn(200);
        $(".priority_form").fadeIn(200);
        window.scroll(0, 0);
    })
    $("#hide_priority_form").on("click", function () {
        $(".priority_form").fadeOut(0);
        $("#show_upload_form").fadeIn(200);
        $("#show_priority_form").fadeIn(200);
        $("#show_delete_form").fadeIn(200);
        $(".priority").fadeOut(0);
        $(".priority_msg").fadeOut(0);
        $(".priority_form").fadeOut(0);
        window.scroll(0, 0);
    })
    // 削除フォーム
    $("#show_delete_form").on("click", function () {
        $("#show_upload_form").fadeOut(0);
        $("#show_priority_form").fadeOut(0);
        $("#show_delete_form").fadeOut(0);
        $(".delete").fadeIn(200);
        $(".delete_msg").fadeIn(200);
        $(".delete_form").fadeIn(200);
        window.scroll(0, 0);
    })
    $("#hide_delete_form").on("click", function () {
        $(".priority_form").fadeOut(0);
        $("#show_upload_form").fadeIn(200);
        $("#show_priority_form").fadeIn(200);
        $("#show_delete_form").fadeIn(200);
        $(".delete").fadeOut(0);
        $(".delete_msg").fadeOut(0);
        $(".delete_form").fadeOut(0);
        window.scroll(0, 0);
    })

    //############################################################
    // 共通：写真をドラッグ＆ドロップ
    //############################################################
    $(document).on("dragover drop", function (e) {
        e.preventDefault();
    });
    var target = $(".preview_area");
    target.on("drop", function (e) {
        $(".text").fadeOut(0);
        $(".preview_icon").fadeOut(0);
        $("[name='upfile']").prop('files', e.originalEvent.dataTransfer.files);
        var reader = new FileReader();
        reader.onload = function (e) {
            target.attr('src', e.target.result);
        }
        reader.readAsDataURL(e.originalEvent.dataTransfer.files[0]);
        $(".preview_area").fadeIn(0);
        $("#delete_image").fadeIn(300);
        // カウンセラーの場合
        $("#delete_flg").val("0");
    });

    //############################################################
    // 写真選択 クリックアクション
    //############################################################
    $(".preview").on("click", function (e) {
        $("#upfile").click();
    })
    $("#upfile").on("change", function () {
        var files = $(this)[0].files;
        $(".text").fadeOut(0);
        $(".preview_icon").fadeOut(0);
        $("[name='upfile']").prop('files', files);
        var reader = new FileReader();
        reader.onload = function (e) {
            target.attr('src', e.target.result);
        }
        reader.readAsDataURL(files[0]);
        $(".preview_area").fadeIn(0);
        $("#delete_image").fadeIn(300);
        // カウンセラーの場合
        $("#delete_flg").val("0");
    });

    //############################################################
    // 写真の優先順位を変更
    //############################################################
    $("#update_priority").on("click", function () {
        if (!confirm("優先順位の変更を登録しますか？")) {
            return;
        }
        $("#update_priority").css('pointer-events', 'none');
        var priority = ""
        $(".priority_no").each(function (i, e) {
            priority += $(e).attr("id").replace("priority_", "") + ":" + $(e).val() + ","
        });
        if (priority != "") {
            priority = priority.slice(0, -1)
        }
        $.ajax({
            url: URL + "/s/update_img_priority",
            type: "POST",
            data: {
                priority: priority,
                id: $("#id").val()
            },
            dataType: "json"
        })
            .done(function (data) {
                location.href = "/s/salon_img?id=" + $("#id").val() + "&m=p";
            })
            .fail(function () {
                alert(ERROR_MSG);
                $("#update_priority").css('pointer-events', 'auto');
            })
    })

    //############################################################
    // 画像掲載順位のblur処理
    //############################################################
    $(".priority_no").on("blur", function () {
        $(this).val($(this).val().replace(/[^0-9]/g, ''));
    })

    //############################################################
    // 写真を削除
    //############################################################
    $("#delete_img").on("click", function () {
        var delete_img = ""
        $(".delete_img").each(function (i, e) {
            if ($(e).prop('checked')) {
                delete_img += $(e).attr("id").replace("delete_", "") + ","
            }
        });
        if (delete_img != "") {
            delete_img = delete_img.slice(0, -1)
        } else {
            alert("削除する写真を選択してください");
            return;
        }

        if (!confirm('選択した写真を削除しますか？\n【注意！】削除した写真は元に戻せません')) {
            return;
        }
        $("#delete_img").css('pointer-events', 'none');
        $.ajax({
            url: URL + "/s/delete_img",
            type: "POST",
            data: {
                delete_img: delete_img,
                id: $("#id").val()
            },
            dataType: "json"
        })
            .done(function (data) {
                location.href = "/s/salon_img?id=" + $("#id").val() + "&m=d";
            })
            .fail(function () {
                alert(ERROR_MSG);
                $("#delete_img").css('pointer-events', 'auto');
            })
    })

    //############################################################
    // 写真をアップロード
    //############################################################
    $("#upload_img").on("click", function () {
        var files = document.getElementById("upfile").files;
        var oFile = files[0];
        let m_file_name = "";
        m_file_name = oFile.name;
        var reader = new FileReader();
        reader.readAsDataURL(oFile);
        if (1048576 < oFile.size) {
            alert("アップロードできませんでした　\n※ アップロードできる画像の最大サイズは1MBです\n  ファイルサイズを変更して、再度画像をセットしてください");
            return;
        }

        if (oFile == undefined) {
            alert("写真をドラッグ＆ドロップしてください");
            return;
        }
        if (!confirm("写真をアップロードしますか？")) {
            return;
        }
        $("#upload_img").css('pointer-events', 'none');
        var img = new Image();

        var files = document.getElementById("upfile").files;
        var oFile = files[0];

        if (oFile != undefined) {
            // 写真あり
            m_file_name = oFile.name;

            if (1048576 < oFile.size) {
                alert("登録できませんでした　\n※ アップロードできる写真の最大サイズは1MBです\n  ファイルサイズを変更して、再度写真をセットしてください");
                $("#confirm_counselor").css('pointer-events', 'auto');
                return;
            }

            var reader = new FileReader();
            reader.readAsDataURL(oFile);
            reader.onload = function () {
                // データURLスキームを取得
                var data_url_scheme = reader.result;
                var file_name = encodeURIComponent(m_file_name);
                $.ajax({
                    url: URL + "/s/upload_img",
                    type: "POST",
                    data: {
                        img: data_url_scheme,
                        width: img.width,
                        height: img.height,
                        id: $("#id").val(),
                        file_name: file_name
                    },
                    dataType: "json"
                })
                    .done(function (data) {
                        if (data.ret == 0) {
                            location.href = "/s/salon_img?id=" + $("#id").val();
                        } else if (data.ret == 1) {
                            alert("アップロードできませんでした\n登録可能な拡張子は[jpg][jpeg][png]のみとなります");
                            $("#upload_img").css('pointer-events', 'auto');
                        } else if (data.ret == 2) {
                            alert("アップロードできませんでした　\n※ アップロードできる画像の最大サイズは1MBです\n  ファイルサイズを変更して、再度画像をセットしてください");
                            $("#upload_img").css('pointer-events', 'auto');
                        }
                    })
                    .fail(function () {
                        alert(ERROR_MSG);
                        $("#upload_img").css('pointer-events', 'auto');
                    })
            }
        }
    })

    //*********************************************************************
    // サロン manage
    //*********************************************************************
    //############################################################
    // 公開/非公開ボタン
    //############################################################
    $(".update_status").on("click", function () {
        let release;
        if ($(this).attr("id") == "salon_open") {
            if (!confirm("サロン情報を公開しますか？")) {
                return;
            }
            release = "1"
        } else if ($(this).attr("id") == "salon_close") {
            if (!confirm("サロン情報を非公開にしますか？")) {
                return;
            }
            release = "0"
        }
        $.ajax({
            url: URL + "/s/update_status",
            type: "POST",
            data: {
                id: $("#id").val(),
                release: release
            },
            dataType: "json"
        })
            .done(function (data) {
                if ($(this).attr("salon_open") == "salon_open") {
                    alert("サロン情報を公開しました");
                } else if ($(this).attr("salon_close") == "salon_close") {
                    alert("サロン情報を非公開にしました");
                }
                location.href = "/s/salon_manage?id=" + $("#id").val();
            })
            .fail(function () {
                alert(ERROR_MSG);
            })

    })

    //############################################################
    // 公開/非公開ボタン
    //############################################################
    $("#delete_salon").on("click", function () {
        $("#delete_salon").css('pointer-events', 'none');
        if (!confirm("このサロンを削除しますか？\n" +
            "【注意！】削除した情報は元に戻せません\n" +
            "アップロードした画像や投稿された口コミも閲覧出来なくなります\n" +
            "（カウンセラーの情報は削除されません）")) {
            $("#delete_salon").css('pointer-events', 'auto');
            return;
        }
        if (!confirm("本当にこのサロンを削除しますか？")) {
            $("#delete_salon").css('pointer-events', 'auto');
            return;
        }
        $.ajax({
            url: URL + "/s/delete_salon",
            type: "POST",
            data: {
                id: $("#id").val()
            },
            dataType: "json"
        })
            .done(function (data) {
                if (data.ret == 0) {
                    alert("サロン情報の削除が完了しました");
                    location.href = "/s/index"
                } else {
                    alert(ERROR_MSG);
                    $("#delete_salon").css('pointer-events', 'auto');
                }
            })
            .fail(function () {
                alert(ERROR_MSG);
                $("#delete_salon").css('pointer-events', 'auto');
            })
    })

    //*********************************************************************
    // サロン詳細情報変更　関連処理
    //*********************************************************************
    //############################################################
    // update_salon
    //############################################################
    $(".update_salon").on("click", function () {
        let path = ""
        let real = ""
        if (location.search != "?c=1") {
            path = "/s/set_data"
            if (!salon_name_validation()) {
                return false;
            }

            if (!salon_real_validation()) {
                return false;
            }

            if (!salon_desc_validation()) {
                return false;
            }

            if (!salon_detail_validation()) {
                return false;
            }
            real = $("input[name='real']:checked").val();
        } else {
            path = "/s/update_salon"
            if (!confirm("サロン情報の変更を登録しますか？")) {
                return;
            }
            real = $("#real").val();
        }
        if(real*1==0){
            $("#prefecture").val("0");
        }
        $(".update_salon").css('pointer-events', 'none');
        $.ajax({
            url: URL + path,
            type: "POST",
            data: {
                func: "salon",
                id: $("#id").val(),
                name: $("#name").val(),
                salon_class: $("#salon_class").val(),
                real: real,
                prefecture: $("#prefecture").val(),
                city: $("#city").val(),
                address: $("#address").val(),
                building: $("#building").val(),
                station1: $("#station1").val(),
                station2: $("#station2").val(),
                station3: $("#station3").val(),
                access: $("#access").val(),
                tel: $("#tel").val(),
                mail_address: $("#mail_address").val(),
                title: $("#title").val(),
                description: $("#description").val(),
                url: $("#url").val(),
                url2: $("#url2").val(),
                url3: $("#url3").val(),
                opening_date: $("#opening_date").val(),
                min_budget: $("#min_budget").val(),
                max_budget: $("#max_budget").val(),
                payment_method: $("#payment_method").val(),
                holiday: $("#holiday").val(),
                private_room: $("#private_room").val(),
                remote: $("#remote").val(),
                text: $("#text").val(),
            },
            dataType: "json"
        })
            .done(function (data) {

                if (data.ret == 0) {
                    if (location.search != "?c=1") {
                        location.href = "/s/salon_edit?c=1";
                    } else {
                        location.href = "/s/salon_manage?id=" + $("#id").val() + "&m=1";
                    }
                } else if (data.ret == 1) {
                    alert("登録できませんでした\nこのサロン名はすでに登録されています");
                    $(".update_salon").css('pointer-events', 'auto');
                    return false;
                }
            })
            .fail(function () {
                alert(ERROR_MSG);
                $(".update_salon").css('pointer-events', 'auto');
            })
    });

    //*********************************************************************
    // サロン新規作成　関連処理
    //*********************************************************************
    //############################################################
    // サロン新規作成の画面遷移
    //############################################################
    $(".change_form").on("click", function () {
        var current_no = $(this).attr("value").split("to")[0] * 1;
        var next_no = $(this).attr("value").split("to")[1] * 1;
        //###################
        // 検索条件の入力チェック
        //###################
        if (current_no == 1 && next_no == 2) {
            if (!salon_name_validation()) {
                return false;
            }
            ;
            $.ajax({
                url: URL + "/s/check_salon_name",
                type: "POST",
                data: {
                    name: $("#name").val()
                },
                dataType: "json"
            })
                .done(function (data) {
                    if (data.ret == 0) {
                        window.scroll({top: 0});
                        $("#form" + current_no).fadeOut(10);
                        $("#form" + next_no).fadeIn(500);
                        $("#form_no").html(next_no);
                    } else if (data.ret == 1) {
                        alert("このサロン名はすでに登録されています");
                    }
                })
            return false;
        } else if (current_no == 2 && next_no == 3) {
            if (!salon_real_validation()) {
                return false;
            }
            ;
        } else if (current_no == 3 && next_no == 4) {
            if (!salon_desc_validation()) {
                return false;
            }
            ;
        } else if (current_no == 4 && next_no == 5) {
            if (!salon_keywords_validation()) {
                return false;
            }
            ;
        }
        window.scroll({top: 0});
        $("#form" + current_no).fadeOut(10);
        $("#form" + next_no).fadeIn(500);
        $("#form_no").html(next_no);
    });

    //############################################################
    // サロン名validation
    //############################################################
    function salon_name_validation() {
        if ($("#name").val() == "") {
            alert("サロンの名前を入力してください");
            return false;
        }
        if ($("#salon_class").val() == "") {
            alert("サロンの種類を入力してください");
            return false;
        }
        if ($("#check_confirm1").length && !$("#check_confirm1").prop('checked')) {
            $(".confirm1_alert").fadeIn(200);
            return false;
        }
        $(".confirm1_alert").fadeOut(50);
        return true;
    };

    //############################################################
    // 予算感validation
    //############################################################
    function salon_real_validation() {
        if ($("input[name='real']:checked").val() != "0") {
            if ($("#prefecture").val() == "0") {
                alert("都道府県を選択してください");
                return false;
            }
            if ($("#city").val() == "") {
                alert("市区を入力してください");
                return false;
            }
            if ($("#address").val() == "") {
                alert("町村・番地を入力してください");
                return false;
            }
            if ($("#station1").val() == "" && $("#station2").val() == "" && $("#station3").val() == "") {
                alert("最寄駅を最低一つ入力してください");
                return false;
            }
            // 重複チェック
            for (let i = 1; i <= 3; i++) {
                var val = $("#station" + i).val();
                if (val == "") {
                    continue;
                }
                for (let j = 1; j <= 3; j++) {
                    if (i != j && val == $("#station" + j).val()) {
                        alert("最寄駅の入力に重複があります")
                        return false;
                    }
                }
            }
        }
        //if ($("#tel").val() == "") {
        //    alert("電話番号を入力してください");
        //    return false;
        //}
        return true;
    };

    //############################################################
    // 説明validation
    //############################################################
    function salon_desc_validation() {
        if ($("#title").val() == "") {
            alert("サロンの説明（タイトル部分）を入力してください");
            return false;
        }
        if ($("#description").val() == "") {
            alert("サロンの説明（本文）を入力してください");
            return false;
        }
        return true;
    };

    //############################################################
    // サロン詳細validation
    //############################################################
    function salon_detail_validation() {

        if ($("#mail_address").length && $("#mail_address").val() != "" && !$("#mail_address").val().match(MAIL_PATTERN)) {
            alert("メールアドレスを正しく入力してください");
            return false;
        }

        if ($("#min_budget").val() == "" || $("#max_budget").val() == "") {
            alert("予算感を入力してください");
            return false;
        }
        if (isNaN($("#min_budget").val()) && isNaN($("#min_budget").val())) {
            alert("予算感は数字で入力ください");
            return false;
        }
        if ($("#min_budget").val()*1 > $("#max_budget").val()*1) {
            alert("予算感の入力が正しくありません");
            return false;
        }
        if ($("#holiday").val() == "") {
            alert("定休日を入力してください");
            return false;
        }
        return true;
    };

    //############################################################
    // 検索条件validation
    //############################################################
    function salon_keywords_validation() {
        // 1つ以上は必須
        var val = ""
        for (let i = 0; i < 20; i++) {
            val += $("#keyword" + i).val();
        }
        if (val == "") {
            alert("検索条件は最低一つは設定してください");
            return false;
        }
        // 重複チェック
        for (let i = 0; i < 20; i++) {
            var val = $("#keyword" + i).val();
            if (val == "") {
                continue;
            }
            for (let j = 0; j < 20; j++) {
                if (i != j && val == $("#keyword" + j).val()) {
                    alert("検索条件の設定項目に重複があります （項目：" + $("#keyword" + j).children(':selected').text() + ")")
                    return false;
                }
            }
        }
        return true;
    };

    //############################################################
    // サロン新規作成の確認画面から戻る
    //############################################################
    $("#back_salon_new").on("click", function () {
        var url = "/s/new";
        url += "?flg=1"
        url += "&name=" + $("#name").val();
        url += "&salon_class=" + $("#salon_class").val();
        url += "&real=" + $("#real").val();
        url += "&prefecture=" + $("#prefecture").val();
        url += "&city=" + $("#city").val();
        url += "&address=" + $("#address").val();
        url += "&building=" + $("#building").val();
        url += "&station1=" + $("#station1").val();
        url += "&station2=" + $("#station2").val();
        url += "&station3=" + $("#station3").val();
        url += "&tel=" + $("#tel").val();
        url += "&title=" + $("#title").val();
        url += "&description=" + $("#description").val();
        url += "&url=" + $("#url").val();
        url += "&url2=" + $("#url2").val();
        url += "&url3=" + $("#url3").val();
        url += "&min_budget=" + $("#min_budget").val();
        url += "&max_budget=" + $("#max_budget").val();
        url += "&payment_method=" + $("#payment_method").val();
        url += "&holiday=" + $("#holiday").val();
        url += "&private_room=" + $("#private_room").val();
        url += "&remote=" + $("#remote").val();
        for (let i = 0; i <= 19; i++) {
            url += "&keyword" + i + "=" + $("#keyword" + i).val();
        }
        location.href = url
    })

    //############################################################
    // サロン新規作成の検索条件追加
    //############################################################
    $(".add_keyword").on("click", function () {
        if ($("#keyword19").css('display') != 'none') {
            alert("検索条件は20件までの設定となります");
            return;
        }
        for (let i = 1; i <= 4; i++) {
            if ($("#keyword" + i * 4).css('display') == 'none') {
                for (let j = 0; j < 4; j++) {
                    $("#keyword" + (i * 4 + j)).fadeIn(200);
                }
                break;
            }
        }
    });

    //############################################################
    // サロン実店舗ありなしのラジオボタン
    //############################################################
    $('[name="real"]:radio').on('change', function () {
        if ($('[id=real0]').prop('checked')) {
            $('.form2_d').hide(200);
        } else if ($('[id=real1]').prop('checked')) {
            $('.form2_d').show(200);
        }
    });

    //############################################################
    // 予算感入力のblur処理
    //############################################################
    $(".budget").on("blur", function () {
        $("#min_budget").val($("#min_budget").val().replace(/[^0-9]/g, ''));
        $("#max_budget").val($("#max_budget").val().replace(/[^0-9]/g, ''));
    })

    //############################################################
    // 予算感入力のblur処理
    //############################################################
    $("#tel").on("blur", function () {
        $("#tel").val($("#tel").val().replace(/[^0-9\-]/g, ''));
    })

    //############################################################
    // create_salon
    //############################################################
    $(".create_salon").on("click", function () {
        let real = "";
        let href = "";
        let path = "";
        if (location.pathname == "/s/new") {
            path = "/s/set_data"
            if (!salon_detail_validation()) {
                return false;
            }
            ;
            real = $("input[name='real']:checked").val();
        } else if (location.pathname == "/s/salon_confirm") {
            path = "/s/create_salon"
            if (!confirm("この内容でサロン情報を登録しますか？")) {
                return;
            }
            real = $("#real").val()
        }
        if(real*1==0){
            $("#prefecture").val("0");
        }
        $(".create_salon").css('pointer-events', 'none');
        $.ajax({
            url: URL + path,
            type: "POST",
            data: {
                func: "salon",
                name: $("#name").val(),
                salon_class: $("#salon_class").val(),
                real: real,
                prefecture: $("#prefecture").val(),
                city: $("#city").val(),
                address: $("#address").val(),
                building: $("#building").val(),
                station1: $("#station1").val(),
                station2: $("#station2").val(),
                station3: $("#station3").val(),
                tel: $("#tel").val(),
                title: $("#title").val(),
                description: $("#description").val(),
                url: $("#url").val(),
                url2: $("#url2").val(),
                url3: $("#url3").val(),
                min_budget: $("#min_budget").val(),
                max_budget: $("#max_budget").val(),
                payment_method: $("#payment_method").val(),
                holiday: $("#holiday").val(),
                private_room: $("#private_room").val(),
                remote: $("#remote").val(),
                keyword0: $("#keyword0").val(),
                keyword1: $("#keyword1").val(),
                keyword2: $("#keyword2").val(),
                keyword3: $("#keyword3").val(),
                keyword4: $("#keyword4").val(),
                keyword5: $("#keyword5").val(),
                keyword6: $("#keyword6").val(),
                keyword7: $("#keyword7").val(),
                keyword8: $("#keyword8").val(),
                keyword9: $("#keyword9").val(),
                keyword10: $("#keyword10").val(),
                keyword11: $("#keyword11").val(),
                keyword12: $("#keyword12").val(),
                keyword13: $("#keyword13").val(),
                keyword14: $("#keyword14").val(),
                keyword15: $("#keyword15").val(),
                keyword16: $("#keyword16").val(),
                keyword17: $("#keyword17").val(),
                keyword18: $("#keyword18").val(),
                keyword19: $("#keyword19").val()
            },
            dataType: "json"
        })
            .done(function (data) {
                if (data.ret == 1) {
                    alert("登録できませんでした\nこのサロン名はすでに登録されています");
                    $(".create_salon").css('pointer-events', 'auto');
                    return false;
                }
                if (location.pathname == "/s/new") {
                    href = "/s/salon_confirm";
                } else if (location.pathname == "/s/salon_confirm") {
                    href = "/s/success";
                }
                location.href = href;
            })
            .fail(function () {
                alert(ERROR_MSG);
                $(".create_salon").css('pointer-events', 'auto');
            })
    });

    //*********************************************************************
    // サロン設定 関連処理
    //*********************************************************************
    $("#update_salon_config").on("click", function () {
        if ($("#job_name").val() == "") {
            alert("カウンセラーの呼称を入力してください");
            return;
        }

        if (!confirm("サロンの設定を更新しますか？")) {
            return;
        }
        $("#update_salon_config").css('pointer-events', 'none');
        $.ajax({
            url: URL + "/s/update_salon_config",
            type: "POST",
            data: {
                id: $("#id").val(),
                job_name: $("#job_name").val(),
            },
            dataType: "json"
        })
            .done(function (data) {
                alert("サロンの設定変更が完了しました");
                location.href = "/s/salon_manage?id=" + $("#id").val();
            })
            .fail(function () {
                alert(ERROR_MSG);
                $("#update_salon_config").css('pointer-events', 'auto');
            })
    });


    //############################################################
    // 予約機能の設定
    // reserve_config
    //############################################################
    // サロン選択
    $('.reserve-config-salon').on('click', function() {
        let salon_id = $(this).attr('sid');
        $("#reserve-config-salons").fadeOut(0);
        $(".salon-config[sid="+salon_id+"]").fadeIn(360);
    });

    // 戻るボタン
    $('.reserve-config-cancel').on('click', function(){
        $(this).closest('.salon-config').fadeOut(0);
        $("#reserve-config-salons").fadeIn(360);
    });

    //############################################################
    // 予約設定の更新
    // update_reserve_config
    //############################################################
    $(".checkbox-reservation-after").on("change", function () {
        if($(this).prop('checked')) {
            $(".after-time-wrapper").fadeIn(200);
        }else{
            $(".after-time-wrapper").fadeOut(0);
            $(".reservation-after-time").val("0");
        }
    });

    //############################################################
    // 予約設定の更新
    // update_reserve_config
    //############################################################
    $(".update_reserve_config").on("click", function () {
        let reservation_after_time = $(".reservation-after-time").val();
        let reservation_interval = $(".reservation-interval").val();
        let cancel_policy = $(".cancel-policy").val();
        let notes = $(".notes").val();
        let telephoneAvailableBeforeTime = "";
        let telephoneAvailableAfterTime = "";
        let subject = $("#mail-subject").val();
        let body    = $("#mail-body").val();
        if ( Number(reservation_after_time) < 0 ) {
            alert("予約間の休憩時間は0分以上に設定してください");
            return;
        }
        let intervals = [10,15,20,30,60]
        let input = Number(reservation_interval);
        if ( intervals.indexOf(input) < 0 ) {
            alert("予約受付の間隔は10分以上で設定してください");
            return;
        }
        if(cancel_policy==""){
            alert("キャンセルポリシーを入力してください");
            return;
        }
        if(notes==""){
            alert("ご利用に際しての注意事項を入力してください");
            return;
        }
        if ( $("#telephone-available-before-time").length ){
            let val = $("#telephone-available-before-time").val();
            if ( val.trim() === "" ){
                alert("今すぐ電話鑑定の受付時間を入力してください");
                return;
            }
            else if ( val < 10 || val > 120 ){
                alert("今すぐ電話鑑定の受付時間は10分〜120分の間を入力してください");
                return;
            }
            telephoneAvailableBeforeTime = val;
        }

        if ( ! validTestMailSubject(subject) ) { return false; }
        if ( ! validTestMailBody(body) ) { return false; }
        $.ajax({
            url: URL + "/s/update_reserve_config",
            type: "POST",
            data: {
                id: $("#salon_reservation").val(),
                take_a_break1: $('.checkbox-break1')[0].checked ? 1 : 0,
                take_a_break2: $(".checkbox-break2")[0].checked ? 1 : 0,
                reserve_in_break: $(".reserve_in_break")[0].checked ? 1 : 0,
                reservation_after: $(".checkbox-reservation-after")[0].checked ? 1 : 0,
                reservation_after_time: reservation_after_time,
                reservation_interval: reservation_interval,
                cancel_policy: cancel_policy,
                notes: notes,
                question: $(".question").val(),
                reservation_mail_subject: subject,
                reservation_mail_body: body
            },
            dataType: "json"
        })
            .done(function (data) {
                alert("予約設定の変更が完了しました");
                location.href = location.href;
            })
            .fail(function () {
                alert(ERROR_MSG);
                $("#update_reserve_config").css('pointer-events', 'auto');
            })
    });

    $('.reservation-after-time').on('input', function(e) {
        ValidNumberOnly(e);
    });

    //############################################################
    // 予約設定　キャンセルポリシー サンプル分読み込み
    //############################################################
    $(".set-cp-sample").on("click", function () {
        let elm = $(this).closest('.salon-config');
        $(elm).find(".cancel-policy").val($("#cancel-policy-sample").val());
    });

    //############################################################
    // 予約設定　サロンからの注意事項 サンプル分読み込み
    //############################################################
    $(".set-n-sample").on("click", function () {
        let elm = $(this).closest('.salon-config');
        $(elm).find(".notes").val($("#notes-sample").val());
    });

    //############################################################
    // 予約設定　受付メール件名 サンプル文読み込み
    //############################################################
    if ( $("#mail-subject").length ) {
        if ( $("#mail-subject").val().trim() === "" ) {
            $("#mail-subject").val( $("#mail-subject-sample").val() );
        }
        $(".set-subject-sample").on("click", function () {
            let elm = $(this).closest('.salon-config');
            $(elm).find("#mail-subject").val($("#mail-subject-sample").val());
        });
    }

    //############################################################
    // 予約設定　受付メール本文 サンプル文読み込み
    //############################################################
    if ( $("#mail-body").length ) {
        if ( $("#mail-body").val().trim() === "" ) {
            $("#mail-body").val( $("#mail-body-sample").val() );
        }
        $(".set-body-sample").on("click", function () {
            let elm = $(this).closest('.salon-config');
            $(elm).find("#mail-body").val($("#mail-body-sample").val());
        });
    }

    //############################################################
    // 予約設定　受付メール テスト送信
    //############################################################
    // # テストメール送信
    $("#send-reservation-test-mail").on("click", function () {
        $(this).css('pointer-events', 'none');
        let subject = $("#mail-subject").val();
        let body    = $("#mail-body").val();
        // バリデーション
        if ( ! validTestMailSubject(subject) ) { return false; }
        if ( ! validTestMailBody(body) ) { return false; }
        // POST
        $.ajax({
            url: URL + "/s/send_reservation_test_mail",
            type: "POST",
            data: {
                id: $(this).attr('salonId'),
                subject: subject,
                body: body
            },
            dataType: "json"
        })
            .done(function (data) {
                alert(data.address + ' 宛に、テストメールを送信しました。');
                $(this).css('pointer-events', 'auto');
            })
            .fail(function () {
                alert(ERROR_MSG);
                $(this).css('pointer-events', 'auto');
            });
    });

    // # バリデーション
    // ## 件名
    function validTestMailSubject(text) {
        if ( text.trim() === "" ) {
            alert('予約受付の自動送信メールの件名は必須です'); return false;
        }
        if ( text.length > 100 ) {
            alert('予約受付の自動送信メールの件名は100文字までです'); return false;
        }
        return true;
    }
    // ## 本文
    function validTestMailBody(text) {
        if ( text.trim() === "" ) {
            alert('予約受付の自動送信メールの本文は必須です'); return false;
        }
        if ( text.length > 1000 ) {
            alert('予約受付の自動送信メールの本文は1,000文字までです'); return false;
        }
        return true;
    }

    //############################################################
    // reserve_log
    // 受付処理済みの予約ページ
    //############################################################
    if ($('#reserve-log-wrapper').length) {

        // *** メール予約の対応完了フラグON
        $('.complete-reservation').on('click',function(){
            $(this).css('pointer-events', 'none');
            $.ajax({
                url: URL + "/s/complete_reservation",
                type: "POST",
                data: {
                    id: $(this).attr('rid')
                },
                dataType: "json"
            })
                .done(function (data) {
                    alert("該当の予約を、対応済みに変更しました。");
                    location.reload();
                })
                .fail(function () {
                    alert(ERROR_MSG);
                    $(this).css('pointer-events', 'auto');
                })
        });

        // *** ミーティングURLの送信完了フラグのトグル
        $('.toggle-sent-url').on('click',function(){
            let elm = $(this);
            elm.css('pointer-events', 'none');

            // すでに送信済みフラグONなら、未送信に戻すか確認する
            let sent = false;
            if (elm.hasClass('sent')) {
                if( confirm('未送信に戻しますか?') ){
                    sent = true;
                }else{
                    return false;
                }
            }

            $.ajax({
                url: URL + "/s/toggle_sent_url_flg",
                type: "POST",
                data: {
                    id: elm.attr('rid')
                },
                dataType: "json"
            })
                .done(function (data) {
                    if(data['result']){
                        if (sent) {
                            alert("ミーティングURLを「未送信」に変更しました。");
                            elm.removeClass('sent')
                                .text('URLを送付済みにする')
                                .css('pointer-events', 'auto')
                        }else{
                            alert("ミーティングURLを「送信済み」に変更しました。");
                            elm.addClass('sent')
                                .text('URL送付済み')
                                .css('pointer-events', 'auto')
                        }
                    }else{
                        alert(ERROR_MSG);
                        $(this).css('pointer-events', 'auto');
                    }
                })
                .fail(function () {
                    alert(ERROR_MSG);
                    $(this).css('pointer-events', 'auto');
                })
        });

    }



    //*********************************************************************
    // アカウント設定関連処理
    //*********************************************************************
    //############################################################
    // パスワード変更処理
    //############################################################
    $("#update_password").on("click", function () {
        if ($("#current").val() == "") {
            alert("現在のパスワードを入力してください");
            return false;
        }
        if ($("#new_password1").val() == "") {
            alert("新しいパスワードを入力してください");
            return false;
        }
        if (!$("#new_password1").val().match(PASSWORD_PATTERN)) {
            alert("パスワードは英数字混在で入力してください");
            return false;
        }
        if ($("#new_password1").val().length < 8) {
            alert("パスワードは8文字以上で入力してください");
            return false;
        }
        if ($("#new_password2").val() == "") {
            alert("パスワード(再確認)を入力してください");
            return false;
        }
        if ($("#new_password1").val() != $("#new_password2").val()) {
            alert("パスワード(再確認)の入力が正しくありません");
            return false;
        }
        if (!confirm("パスワードを変更しますか？")) {
            return;
        }
        $("#update_password").css('pointer-events', 'none');
        $.ajax({
            url: URL + "/s/update_password",
            type: "POST",
            data: {
                current: $("#current").val(),
                new: $("#new_password1").val()
            },
            dataType: "json"
        })
            .done(function (data) {
                if (data.ret == 0) {
                    alert("パスワードが正常に変更されました\n新しいパスワードで再度ログインしてください");
                    location.href = "/s/login";
                } else {
                    alert("現在のパスワードが違います");
                    $("#update_password").css('pointer-events', 'auto');
                }
            })
            .fail(function () {
                alert(ERROR_MSG);
                $("#update_password").css('pointer-events', 'auto');
            })
    });

    //*********************************************************************
    // アカウント関連処理
    //*********************************************************************
    //############################################################
    // アカウント情報のラジオボタン
    //############################################################
    $('[name="kinds"]:radio').on('change', function () {
        if ($('[id=kinds0]').prop('checked')) {
            $('.company').fadeOut(300);
        } else if ($('[id=kinds1]').prop('checked')) {
            $('.company').fadeIn(300);
        }
    });

    //############################################################
    // アカウント入力 validation
    //############################################################
    function account_validation() {

        if (location.pathname == "/s/regist") {
            if ($("#login_name").val() == "") {
                alert("ログインIDを入力してください");
                return false;
            }
            if ($("#password").val() == "") {
                alert("パスワードを入力してください");
                return false;
            }
            if (!$("#password").val().match(PASSWORD_PATTERN)) {
                alert("パスワードは英数字混在で入力してください");
                return false;
            }
            if ($("#password").val().length < 8) {
                alert("パスワードは8文字以上で入力してください");
                return false;
            }
            if ($("#password_check").val() == "") {
                alert("パスワード(再確認)を入力してください");
                return false;
            }
            if ($("#password").val() != $("#password_check").val()) {
                alert("パスワード(再確認)の入力が正しくありません");
                return false;
            }
        }
        if ($("#last_name").val() == "") {
            alert("姓を入力してください");
            return false;
        }
        if ($("#first_name").val() == "") {
            alert("名を入力してください");
            return false;
        }
        if ($("input[name='kinds']:checked").val() == "1") {
            if ($("#company_name").val() == "") {
                alert("組織名を入力してください");
                return false;
            }
        }
        if ($("#prefecture").val() == "") {
            alert("都道府県を入力してください");
            return false;
        }
        if ($("#city").val() == "") {
            alert("市区を入力してください");
            return false;
        }
        if ($("#address").val() == "") {
            alert("町村・番地を入力してください");
            return false;
        }
        //if ($("#tel").val() == "") {
        //    alert("電話番号を入力してください");
        //    return false;
        //}
        if ($("#mail").val() == "") {
            alert("メールアドレスを入力してください");
            return false;
        }
        if (!$("#mail").val().match(MAIL_PATTERN)) {
            alert("メールアドレスを正しく入力してください");
            return false;
        }
        return true;
    };
    //*********************************************************************
    // salon_edit
    //*********************************************************************
    //############################################################
    // update_account
    //############################################################
    $(".update_account").on("click", function () {
        let path = "";
        let href = "";
        if (location.pathname == "/s/account") {
            path = "/s/set_data"
            if (!account_validation()) {
                return;
            }
        } else if (location.pathname == "/s/account_confirm") {
            path = "/s/update_account"
        }
        let kinds = $("input[name='kinds']:checked").val();
        if (kinds == "0") {
            $("#company_name").val("");
            $("#division").val("");
        }
        $("#update_account").css('pointer-events', 'none');
        $.ajax({
            url: URL + path,
            type: "POST",
            data: {
                func: "account",
                last_name: $("#last_name").val(),
                first_name: $("#first_name").val(),
                kinds: kinds,
                company_name: $("#company_name").val(),
                division: $("#division").val(),
                prefecture: $("#prefecture").val(),
                city: $("#city").val(),
                address: $("#address").val(),
                building: $("#building").val(),
                tel: $("#tel").val(),
                mail: $("#mail").val()
            },
            dataType: "json"
        })
            .done(function (data) {
                if (location.pathname == "/s/account") {
                    href = "/s/account_confirm";
                } else if (location.pathname == "/s/account_confirm") {
                    if (data.ret == 0) {
                        href = "/s/success?f=2";
                    } else if (data.ret == 1) {
                        alert("処理を完了できませんでした\nこのメールアドレスは既に登録されています");
                        $("#update_account").css('pointer-events', 'auto');
                    }
                }
                location.href = href;
            })
            .fail(function () {
                alert(ERROR_MSG);
                $("#update_account").css('pointer-events', 'auto');
            })
    });

    //*********************************************************************
    // ログイン
    //*********************************************************************
    //############################################################
    // ログイン処理
    //############################################################
    $("#login").on("click", function () {
        if ($("#lname").val() == "") {
            alert("ログイン名を入力してください");
            return;
        }
        if ($("#password").val() == "") {
            alert("パスワードを入力してください");
            return;
        }
        $("#login").css('pointer-events', 'none');
        $.ajax({
            url: URL + "/s/login_req",
            type: "POST",
            data: {
                login_name: $("#lname").val(),
                password: $("#password").val(),
            },
            dataType: "json"
        })
            .done(function (data) {
                if (data.ret == "1") {
                    location.href = "/s/index";
                } else {
                    alert("ログイン名またはパスワードが違います");
                    $("#login").css('pointer-events', 'auto');
                }
            })
            .fail(function () {
                alert(ERROR_MSG);
                $("#login").css('pointer-events', 'auto');
            })

    });
    //############################################################
    // カウンセラーログイン処理
    //############################################################
    $("#counselor_login").on("click", function () {
        if ($("#lname").val() == "") {
            alert("サロンIDを入力してください");
            return;
        }
        if ($("#cname").val() == "") {
            alert("カウンセラーIDを入力してください");
            return;
        }
        if ($("#password").val() == "") {
            alert("パスワードを入力してください");
            return;
        }
        $("#counselor_login").css('pointer-events', 'none');
        $.ajax({
            url: URL + "/s/counselor_login_req",
            type: "POST",
            data: {
                account: $("#lname").val(),
                counselor: $("#cname").val(),
                password: $("#password").val(),
            },
            dataType: "json"
        })
            .done(function (data) {
                if (data.ret == "1") {
                    location.href = "/s/counselors_all";
                } else {
                    alert("ログイン名またはパスワードが違います");
                    $("#counselor_login").css('pointer-events', 'auto');
                }
            })
            .fail(function () {
                alert(ERROR_MSG);
                $("#counselor_login").css('pointer-events', 'auto');
            })

    });

    //*********************************************************************
    // サポート問い合わせ
    //*********************************************************************
    $(".send_support").on("click", function () {
        var req = "send_support"
        if ($(this).attr("id") == "confirm_support") {
            req = "set_data"
            if ($("#name").val() == "" || $("#mail").val() == "") {
                alert("未入力の項目があります");
                return;
            }
            if ($("#withdrawal").val() != "1" && $("#text").val() == "") {
                alert("未入力の項目があります");
                return;
            }
            if ($("#withdrawal").val() == "1" && $("#withdrawal_class").val() == "") {
                alert("退会の理由を選択してください");
                return;
            }
            if ($("#withdrawal").val() == "1" && $("#text").val() == "" && $("#withdrawal_class").val() == "その他") {
                alert("退会理由の詳細を入力してください");
                return;
            }
        }
        if (!$("#mail").val().match(MAIL_PATTERN)) {
            alert("メールアドレスを正しく入力してください");
            return false;
        }
        $(".send_support").css('pointer-events', 'none');
        $.ajax({
            url: URL + "/s/" + req,
            type: "POST",
            data: {
                func: "support",
                inquiry_class: $("#inquiry_class").val(),
                withdrawal_class: $("#withdrawal_class").val(),
                name: $("#name").val(),
                mail: $("#mail").val(),
                text: $("#text").val(),
                withdrawal: $("#withdrawal").val()
            },
            dataType: "json"
        })
            .done(function (data) {
                var href = "/s/success?f=1"
                if (req == "set_data") {
                    href = "/s/support?c=1";
                }
                if (req == "send_support" && $("#withdrawal").val() == "1") {
                    href = "/s/success?f=3";
                }
                location.href = href;
            })
            .fail(function () {
                alert(ERROR_MSG);
                $(".send_support").css('pointer-events', 'auto');
            })
    });

    //############################################################
    // お問合せ種別選択
    //############################################################
    $("#inquiry_class").on("change", function () {
        if ($(this).val() == "マイシルのサロン会員を退会したい") {
            $(".inquiry_text").fadeOut(0);
            $(".inquiry_tr").fadeOut(0);
            $(".withdrawal_tr").fadeIn(360);
            $(".withdrawal_text").fadeIn(360);
            $("#withdrawal").val(1);
        } else {
            $(".withdrawal_tr").fadeOut(0);
            $(".withdrawal_text").fadeOut(0);
            $(".inquiry_tr").fadeIn(360);
            $(".inquiry_text").fadeIn(360);
            $("#withdrawal").val(0);
        }
    });

    //############################################################
    // 退会理由選択
    //############################################################
    $("#withdrawal_class").on("change", function () {
        if ($(this).val() == "その他") {
            $(".inquiry_tr").fadeIn(0);
        } else {
            $(".inquiry_tr").fadeOut(360);
        }
    });

    //*********************************************************************
    // アカウント登録
    //*********************************************************************
    //############################################################
    // ログイン名重複チェック
    //############################################################
    $("#login_name").keyup(function (event) {
        if ($("#login_name").val() == "") {
            $("#login_name_msg1").fadeOut(0);
            $("#login_name_msg2").fadeOut(0);
            return false;
        }
        $.ajax({
            url: URL + "/s/check_account_name",
            type: "POST",
            data: {
                name: $("#login_name").val()
            },
            dataType: "json"
        })
            .done(function (data) {
                if (data.ret == 0) {
                    $("#login_name_msg2").fadeOut(0)
                    $("#login_name_msg1").fadeIn(120)
                } else if (data.ret == 1) {
                    $("#login_name_msg1").fadeOut(0)
                    $("#login_name_msg2").fadeIn(120)
                }
            })
    })

    //############################################################
    // アカウント登録 処理
    //############################################################
    $(".regist_account").on("click", function () {
        let path = "";
        let href = "";
        if (location.pathname == "/s/regist") {
            if (!$("#agreement").prop('checked')) {
                $("#agreement_msg").fadeIn(200);
                return;
            }
            path = "/s/set_regist_data"
            if (!account_validation()) {
                return;
            }
            if (!$("#login_name_msg1").is(":visible")) {
                alert("このログインIDは登録できません");
                return;
            }

        } else if (location.pathname == "/s/regist_confirm") {
            path = "/s/create_account"
            if (!confirm("この内容でアカウント情報を登録しますか？")) {
                return;
            }
        }
        $("#create_account").css('pointer-events', 'none');
        let kinds = $("input[name='kinds']:checked").val();
        if (kinds == "0") {
            $("#company_name").val("");
            $("#division").val("");
        }
        $.ajax({
            url: URL + path,
            type: "POST",
            data: {
                func        :"regist",
                login_name  :$("#login_name").val(),
                password    :$("#password").val(),
                last_name   :$("#last_name").val(),
                first_name  :$("#first_name").val(),
                kinds       :kinds,
                company_name:$("#company_name").val(),
                division    :$("#division").val(),
                prefecture  :$("#prefecture").val(),
                city        :$("#city").val(),
                address     :$("#address").val(),
                building    :$("#building").val(),
                tel         :$("#tel").val(),
                check_code  :$("#check_code").val(),
                mail        :$("#mail").val(),
                gift        :$("#gift").val(),
                inflow      :$("#inflow").val(),
                referred_by :$("#referred_by").val(),
                code        :$("#code").val()
            },
            dataType: "json"
        })
            .done(function (data) {
                if (location.pathname == "/s/regist") {
                    href = "/s/regist_confirm?c=" + $("#code").val();
                } else if (location.pathname == "/s/regist_confirm") {
                    href = "/s/regist_success";
                }
                location.href = href;
            })
            .fail(function () {
                alert(ERROR_MSG);
                $("#create_account").css('pointer-events', 'auto');
            })
    });
    //############################################################
    // 同意ボタンチェック
    //############################################################
    $("#agreement").on("change", function () {
        $("#agreement_msg").fadeOut(0);
    })

    //############################################################
    // アカウント登録 処理
    //############################################################
    $("#back_regist").on("click", function () {
        location.href = "/s/regist" + "?c=" + $("#code").val() + "&b=1";
    })

    //*********************************************************************
    // パスワードリセット
    //*********************************************************************
    //############################################################
    // メールアドレスにパスワードリセット用のURLを送信
    //############################################################
    $("#password_reset").on("click", function () {

        if ($("#mail").val() == "") {
            alert("メールアドレスの入力がありません");
            return false;
        }
        if (!$("#mail").val().match(MAIL_PATTERN)) {
            alert("メールアドレスを正しく入力してください");
            return false;
        }
        $("#password_reset").css('pointer-events', 'none');
        $.ajax({
            url: URL + "/s/password_reset_req",
            type: "POST",
            data: {
                mail: $("#mail").val()
            },
            dataType: "json"
        })
            .done(function (data) {
                location.href = "/s/regist_success?f=1";
            })
            .fail(function () {
                alert(ERROR_MSG);
                $("#password_reset").css('pointer-events', 'auto');
            })
    });
    //############################################################
    // パスワード再設定処理
    //############################################################
    $("#new_password").on("click", function () {

        if ($("#password").val() == "") {
            alert("パスワードを入力してください");
            return false;
        }
        if (!$("#password").val().match(PASSWORD_PATTERN)) {
            alert("パスワードは英数字混在で入力してください");
            return false;
        }
        if ($("#password").val().length < 8) {
            alert("パスワードは8文字以上で入力してください");
            return false;
        }
        if ($("#password_check").val() == "") {
            alert("パスワード(再確認)を入力してください");
            return false;
        }
        if ($("#password").val() != $("#password_check").val()) {
            alert("パスワード(再確認)の入力が正しくありません");
            return false;
        }
        $("#new_password").css('pointer-events', 'none');
        $.ajax({
            url: URL + "/s/new_password_req",
            type: "POST",
            data: {
                password: $("#password").val(),
                code: $("#code").val()
            },
            dataType: "json"
        })
            .done(function (data) {
                if (data.ret == 0) {
                    location.href = "/s/regist_success?f=2";
                } else {
                    alert(ERROR_MSG);
                    $("#new_password").css('pointer-events', 'auto');
                }
            })
            .fail(function () {
                alert(ERROR_MSG);
                $("#new_password").css('pointer-events', 'auto');
            })

    });

    //*********************************************************************
    // ログアウト
    //*********************************************************************
    $("#logout").on("click", function () {

        if (!confirm("ログアウトしますか？")) {
            return;
        }

        $.ajax({
            url: URL + "/s/logout",
            type: "POST",
            data: {
                func: "logout"
            },
            dataType: "json"
        })
            .done(function (data) {
                if(data.ret==0) {
                    location.href = "/s/login";
                }else{
                    location.href = "/s/counselor_login";
                }
            })
            .fail(function () {
                alert(ERROR_MSG);
            });
    });

    //*********************************************************************
    // マーケティング用メール送信機能
    //*********************************************************************
    $("#send_mail").on("click", function () {

        let mail = $("#to").val();
        let text = $("#text").val();
        let subject = $("#subject").val();

        if (mail == "" || text == "" || subject == "") {
            alert("宛先と本文と入力してください");
            return;
        }

        if (!$("#to").val().match(MAIL_PATTERN)) {
            alert("メールアドレスを正しく入力してください");
            return false;
        }

        if (!confirm("メール送信しますか？")) {
            return;
        }
        $("#send_mail").css('pointer-events', 'none');

        $.ajax({
            url: URL + "/s/send_mail",
            type: "POST",
            data: {
                mail: mail,
                subject: subject,
                text: text
            },
            dataType: "json"
        })
            .done(function (data) {
                alert("OK");
                $("#to").val("");
                $("#send_mail").css('pointer-events', 'auto');
            })
            .fail(function () {
                alert(ERROR_MSG);
            });
    });
    //*********************************************************************
    // 予約関連
    //*********************************************************************
    //############################################################
    // 予約受付
    //############################################################
    $(".accept_reservation").on("click", function () {
        $("#accept_reservation").css('pointer-events', 'none');
        $.ajax({
            url: URL + "/s/accept_reservation",
            type: "POST",
            data: {
                id: $(this).data("val")
            },
            dataType: "json"
        })
            .done(function (data) {
                alert("受付処理が正常に完了しました");
                location.reload();
            })
            .fail(function () {
                alert(ERROR_MSG);
                $("#accept_reservation").css('pointer-events', 'auto');
            });
    })
    //############################################################
    // 予約キャンセル
    //############################################################
    $(".cancel_reservation").on("click", function () {
        if (!confirm("この予約をキャンセルしますか？")) {
            return;
        }
        loadingModal('show');
        $(this).css('pointer-events', 'none');
        $.ajax({
            url: URL + "/s/cancel_reservation",
            type: "POST",
            data: {
                id: $(this).data("val")
            },
            dataType: "json"
        })
            .done(function (data) {
                alert("予約をキャンセルしました");
                location.reload();
                loadingModal('hide');
            })
            .fail(function () {
                alert(ERROR_MSG);
                $(this).css('pointer-events', 'auto');
                loadingModal('hide');
            });
    })
    //############################################################
    // 予約削除
    //############################################################
    $(".delete_reservation").on("click", function () {
        if (!confirm("この予約を削除しますか？")) {
            return;
        }
        $("#delete_reservation").css('pointer-events', 'none');
        $.ajax({
            url: URL + "/s/delete_reservation",
            type: "POST",
            data: {
                id: $(this).data("val"),
                check_time_tables: true
            },
            dataType: "json"
        })
            .done(function (data) {
                alert("予約を削除しました");
                location.reload();
            })
            .fail(function () {
                alert(ERROR_MSG);
                $("#delete_reservation").css('pointer-events', 'auto');
            });
    })
    //############################################################
    // サロン、年月選択
    //############################################################
    $("#salon_reservation,#ym_reservation").on("change", function () {
        let this_url = "";
        this_url = $(location).attr('pathname');
        this_url = this_url + "?salon=" + $("#salon_reservation").val();
        this_url = this_url + "&ym=" + $("#ym_reservation").val()
        location.href = this_url;
    })
    //############################################################
    // 予約サービス申し込み（次のフォームへ）
    //############################################################
    $("#apply_reserve_next").on("click", function () {
        $(".reserve_desc").fadeOut(0);
        $(".select_salon").fadeIn(200);
        $(".apply_text").fadeIn(200);
        $(".apply_cell").fadeIn(200);
        $("#apply_reserve").fadeIn(200);
    })
    //############################################################
    // 予約サービスキャンセル（次のフォームへ）
    //############################################################
    $("#cancel_reserve_next").on("click", function () {
        $(".reserve_desc").fadeOut(0);
        $(".reserve_salon").fadeOut(0);
        $(".select_salon").fadeIn(200);
        $(".cancel_text").fadeIn(200);
        $(".cancel_cell").fadeIn(200);
        $(".not_valid").fadeIn(200);
        $("#cancel_reserve").fadeIn(200);
    })
    //############################################################
    // 予約サービス申し込み
    //############################################################
    $("#apply_reserve").on("click", function () {
        var reserve_salon = new Array();
        $(".reserve_salon").each(function (i, e) {
            if ($(e).prop('checked')) {
                reserve_salon.push($(this).data('val'));
            }
        })
        if (!reserve_salon.length) {
            alert("サロンの選択がありません");
            return
        }
        if (!confirm("選択したサロンの予約サービスを有効化しますか？")) {
            return;
        }
        $("#apply_reserve").css('pointer-events', 'none');
        $.ajax({
            url: URL + "/s/apply_reserve",
            type: "POST",
            data: {
                flg: 1,
                reserve_salon: reserve_salon
            },
            dataType: "json"
        })
            .done(function (data) {
                $(".apply_msg").fadeIn(200);
                $(".select_salon").fadeOut(0);
                setTimeout(function(){
                    location.href = "/s/reservation_top"
                },2400);
            })
            .fail(function () {
                alert(ERROR_MSG);
                $("#apply_reserve").css('pointer-events', 'auto');
            });
    });
    //############################################################
    // 予約サービスキャンセル
    //############################################################
    $("#cancel_reserve").on("click", function () {
        var cancel_salon = new Array();
        $(".cancel_salon").each(function (i, e) {
            if ($(e).prop('checked')) {
                cancel_salon.push($(this).data('val'));
            }
        })
        if (!cancel_salon.length) {
            alert("サロンの選択がありません");
            return
        }
        if (!confirm("選択したサロンの予約サービスを無効化しますか？")) {
            return;
        }
        $("#cancel_reserve").css('pointer-events', 'none');
        $.ajax({
            url: URL + "/s/apply_reserve",
            type: "POST",
            data: {
                flg: 0,
                reserve_salon: cancel_salon
            },
            dataType: "json"
        })
            .done(function (data) {
                $(".cancel_msg").fadeIn(200);
                $(".select_salon").fadeOut(0);
                setTimeout(function(){
                    location.href = "/s/reservation_top"
                },2400);
            })
            .fail(function () {
                alert(ERROR_MSG);
                $("#cancel_reserve").css('pointer-events', 'auto');
            });
    });

    //############################################################
    // reservation_create
    // 予約の手動作成
    //############################################################
    if( $('#reservation-create-wrapper').length ){
        // **********
        // サロンセレクト
        $('#select-salon').on('change', function(){
            $(this).css('pointer-events', 'none');
            location.replace( '/s/reservation_create?salon_id='+$(this).val() );
        });
        // **********
        // カウンセラーセレクト
        $('#select-counselor').on('change', function(){
            $(this).css('pointer-events', 'none');
            location.replace( '/s/reservation_create?salon_id='+$('#salon-id').val()+'&counselor_id='+$(this).val() );
        });

        // **********
        // 予約可能日時のクリック
        $('.can-reserve').on('click',function(){
            let wrapper = $(this).closest('.date');
            let childs = $(wrapper).find('div.day');
            let index = childs.index(this) + 1;
            let times = [];
            for(let i = index; i < childs.length; i++){
                let target = childs[i];
                times.push( $(target).attr('time') );
                if ( $(target).hasClass('cant-reserve') ){ break; }
            }
            let date = $(this).attr('date');
            let time = $(this).attr('time');
            // モーダルに情報を設定
            $('#modal-title').text('予約をブロックする時間の設定');
            $('#salon-name').text( $('#select-salon option:selected').text() );
            $('#counselor-name').text( $('#select-counselor option:selected').text() );
            $('#date-text').text( formatDate(new Date(date), 'yyyy年M月d日') );
            $('#date').val( date );
            $('#start-time-text').text( time );
            $('#start-time').val( time );
            $('#end-time option').remove();
            $(times).each(function(i,e){
                $('#end-time').append('<option value="'+ e +'">'+ e +'</option>');
            });
            modal('show');
        });

        // **********
        // メニュー選択ボタン
        // $('#select-service').on('click', function(){
        //     $("#select-service").css('pointer-events', 'none');
        //     $.ajax({
        //         url: URL + "/s/get_services_json",
        //         type: "POST",
        //         data: { salon_id: $('#salon-id').val() },
        //         dataType: "json"
        //     })
        //         .done(function (data) {
        //             let counselorID = $('#counselor-id').val();
        //             $('#select-wrapper').remove();
        //             let wrapper = $('<table id="select-wrapper"></table>');
        //             let header = $('<tr></tr>');
        //             header.append('<th>公開状態</th>');
        //             header.append('<th>メニュー名</th>');
        //             header.append('<th>種別</th>');
        //             if( counselorID != "" ){ header.append('<th>選択中の'+$('#job-name').val()+'</th>') }
        //             wrapper.append(header);
        //
        //             $(data).each(function(i,e){
        //                 let record =  $('<tr></tr>');
        //
        //                 let release = $('<td class="release"></td>').text('公開中');
        //                 if ( e['release'] != '1' ) { release.addClass('no-release').text('非公開');}
        //                 record.append(release);
        //
        //                 let name = $('<td class="name"></td>').text(e['name']);
        //                 record.append(name);
        //
        //                 if (e['is_mail'] == 1) {
        //                     record.append($('<td>締切制</td>'));
        //                 }else{
        //                     record.append($('<td>時間制</td>'));
        //                 }
        //
        //                 if ( counselorID != ""){
        //                     if (e['counselors']==null){ e['counselors'] = ""; }
        //                     let counselors = e['counselors'].split(',');
        //                     if ( counselors.indexOf(counselorID) > -1 ) {
        //                         record.append($('<td class="has"></td>').text('受付設定済み'))
        //                     }else{
        //                         record.append($('<td class="hasnt"></td>').text('受付未設定'))
        //                     }
        //                 }
        //
        //                 $(record).on('click', function(){
        //                     $('#service-id').val(e['id']);
        //                     $('#service-name').text(e['name']);
        //                     $('#service-price').text(String(e['price']).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')+'円（税込）');
        //                     if (e['is_mail'] == 1){
        //                         $('#service-time').text(e['time']+' 日後までに回答');
        //                     }else{
        //                         $('#service-time').text(e['time']+' 分');
        //                     }
        //
        //                     modal('hide');
        //                 });
        //
        //                 wrapper.append(record);
        //             });
        //
        //             $('#modal-title').text('メニューを選択');
        //             $('#modal-body').append(wrapper);
        //             modal('show');
        //             $("#select-service").css('pointer-events', 'auto');
        //         })
        //         .fail(function () {
        //             alert(ERROR_MSG);
        //             $("#select-service").css('pointer-events', 'auto');
        //         });
        //
        // });

        // **********
        // 予約作成ボタン
        $('#create-reservation').on('click', function(e){
            $("#create-reservation").css('pointer-events', 'none');
            let data = {};
            // 予約種別
            let type = $('#reservation-type input:checked').attr('id');
            /*
            if ( type == 'reservation-type-normal'){
                // 通常予約ならバリデーション
                if ( $('#date').val() == "" ) {
                    alert('日付を選択してください。');
                    scrollToTarget('#date');
                    return false;
                }
                if ( $('#last-name').val() == "" ){
                    alert('姓を入力してください');
                    scrollToTarget('#last-name');
                    return false;
                }
                if ( $('#first-name').val() == "" ){
                    alert('名を入力してください');
                    scrollToTarget('#first-name');
                    return false;
                }
                if ( $('#counselor-id').val() == "" ){
                    alert($('#job-name').val() + 'を選択してください');
                    scrollToTarget('#counselor-id');
                    return false;
                }
                if ( $('#service-id').val() == "" ){
                    alert('メニューを選択してください');
                    scrollToTarget('#service-id');
                    return false;
                }
                if ( $('#answer') && $('#answer').val() == "" ){
                    alert('質問への回答を入力してください');
                    scrollToTarget('#answer');
                    return false;
                }
                data = {
                    type: 'normal',
                    date: $('#date').val(),
                    start_time: $('#start-time-hour').val() +':'+ $('#start-time-minute').val(),
                    last_name: $('#last-name').val(),
                    first_name: $('#first-name').val(),
                    prefecture: $('#prefecture').val(),
                    tel: $('#tel').val(),
                    mail: $('#mail').val(),
                    first_flg: $('#first-flg:checked').length ? 1 : 0,
                    counselor_id: $('#counselor-id').val(),
                    service_id: $('#service-id').val(),
                    answer: $('#answer').length ? $('#answer').val() : "",
                    text: $('#text').val()
                }
            }else*/
            // 他サイトからの予約
            if ( $('#salon-id').val() == "" ){
                alert('サロンを選択してください');
                $(this).css('pointer-events', 'auto');
                return false;
            }
            if ( $('#date').val() == "" ) {
                alert('日付を選択してください。');
                $(this).css('pointer-events', 'auto');
                return false;
            }
            if ( Number($('#start-time').val().replace(':','')) >= Number($('#end-time').val().replace(':','')) ){
                alert('終了時刻は、開始時刻より後を選択してください。');
                $(this).css('pointer-events', 'auto');
                return false;
            }
            data = {
                type: 'other',
                salon_id: $('#salon-id').val(),
                counselor_id: $('#counselor-id').val(),
                date: $('#date').val(),
                start_time: $('#start-time').val(),
                end_time: $('#end-time').val(),
                text: $('#text').val()
            };

            //POST
            $.ajax({
                url: URL + "/s/create_reservation",
                type: "POST",
                data: data,
                dataType: "json"
            })
                .done(function (data) {
                    if ( data['ret'] == 'success' ) {
                        alert('該当の時間枠の予約をブロックしました');
                        location.replace(location.href);
                    } else {
                        alert(ERROR_MSG);
                    }
                    $("#create-reservation").css('pointer-events', 'auto');
                })
                .fail(function () {
                    alert(ERROR_MSG);
                    $("#create-reservation").css('pointer-events', 'auto');
                });
        });

        // **********
        // スクロール時にヘッダーを固定
        const target = document.querySelector('#io-target');
        let dateWrapperHeight = $('.date-wrapper .date:nth-child(2) div:first-of-type').outerHeight();
        let dateWrapperWidth = $('.date-wrapper .date:nth-child(2) div:first-of-type').outerWidth();
        let weekWrapperHeight = $('.week-wrapper').height();
        let timeFirst = $('.time:first-of-type');
        timeFirst.css("width", timeFirst.outerWidth());

        let headerHeight = $('.header').outerHeight();
        if ($('.page_header_sp').length) headerHeight = $('.page_header_sp').outerHeight();
        let smpPadding = 0;
        if (headerHeight > 0) smpPadding = 10;

        timeFirst.innerHeight(dateWrapperHeight);

        let changeElements = $('.date div:first-of-type');
        const options = {
            root: null,
            rootMargin: 0 - headerHeight + "px",
            threshold: 0
        };
        const callback = function (entry) {
            if (entry[0].isIntersecting) {
                // 画面内に表示されている場合
                $('.week-wrapper').removeClass('d-fixed');
                $(changeElements).removeClass('d-fixed');
                $(timeFirst).removeClass('d-fixed');
                $('.date-wrapper').css("padding-top", 0);
            } else {
                // 画面外に行った場合
                $('.week-wrapper').addClass('d-fixed');
                $(changeElements).addClass('d-fixed');
                $(timeFirst).addClass('d-fixed');
                $('.week-wrapper.d-fixed').css("top", headerHeight -1);
                $('.date-wrapper .d-fixed').css("top", weekWrapperHeight + headerHeight + smpPadding -1);
                $('.date-wrapper .date .d-fixed').css("width", dateWrapperWidth);
                $('.date-wrapper').css("padding-top", weekWrapperHeight + dateWrapperHeight + smpPadding);
            }
        }
        const observer = new IntersectionObserver(callback, options);
        observer.observe(target);
    }

    //############################################################
    // counselor_schedule
    // カウンセラーのスケジュール登録
    //############################################################
    // *** 予約可否チェックボックスのUIトグルと入力補助
    $(function () {
        let dateObj = new Date();
        let yesterday = dateObj.setDate(dateObj.getDate() - 1);

        let records = $('.counselor-schedule-record');

        // チェックボックスの状態チェックとトグル時の処理
        records.each(function (i, record) {

            // 本日以前は編集できないように
            if ( yesterday > (new Date($(record).attr('value'))) ) {
                $(record).find('input').prop('disabled',true);
                $(record).find('select').prop('disabled',true);
                return true;
            }

            let checkboxReserve = $($(record).find('.checkbox-reserve')[0]);
            let inputTimes = $(record).find('.time-reserve,.time-break1,.time-break2');

            toggleInputBoxDisabled($(record), checkboxReserve[0], inputTimes);

            checkboxReserve.on('change', function (ev) {
                toggleInputBoxDisabled($(record), ev.target, inputTimes);
            });
        });

        // 時間を選択したら、自動で00分を入力する補助
        $('.time-wrapper').each(function (i, selected) {
            let selectHour = $(selected).find('.time-hour')[0];
            let selectMinute = $(selected).find('.time-minute')[0];
            $(selectHour).on('change', function (ev) {
                if ( $(selectMinute).val()=='default' || $(this).val()=='24') {
                    $(selectMinute).val('0');
                    $(selectMinute).trigger('input');
                }
            });
            // 24時を選択している時は、分を00に固定させる
            $(selectMinute).on('change', function(){
                if( $(selectHour).val()=='24' ){
                    $(selectMinute).val('0');
                }
            });
        });

        $('.reserve-start-time select').on('input', function(e) {
            check_times_pair(e.target);
        });
        $('.reserve-end-time select').on('input', function(e) {
            check_times_pair(e.target);
        });
        $('.time-wrapper select').on('change', function(e){
            check_auto_clear(e.target);
        });

        // 開始時間と終了時間のペアが揃ったら、自動で予約可にチェックを入れる補助
        function check_times_pair(target) {
            let parent = target.closest('tr.counselor-schedule-record'); // tr
            let times = [];
            $(parent).find('.reserve-start-time select').each(function(i,elm){
                times.push(elm.value);
            });
            $(parent).find('.reserve-end-time select').each(function(i,elm){
                times.push(elm.value);
            });
            if ( times.indexOf('default') < 0 ) {
                let checkbox = $(parent).find('input[type="checkbox"]');
                checkbox.prop('checked', true);
                checkbox.trigger('change');
            }
        }

        // 時間のクリア補助。時もしくは分をハイフンに変更した際、該当のselect値を消す補助
        function check_auto_clear(target) {
            if (target.value == 'default') {
                $(target).closest('.time-wrapper').find('select').each(function(i,e){
                    $(e).val('default');
                });
            }
        }

        // 関数_チェックボックスの状態にあわせて表示変更
        function toggleInputBoxDisabled(record, checkbox, inputs) {
            if ($(checkbox).prop('checked')) {
                record.removeClass('disable');
                inputs.each(function (i, input) {
                });
            } else {
                record.addClass('disable');
                inputs.each(function (i, input) {
                    $(input).val('default');
                });
            }
        }

        // pc用ボタンサイズの可変
        if ( $('.update-counselor-schedule-pc').length ){
            let width = $('#counselor-schedule-table').outerWidth();
            $('.update-counselor-schedule-pc').attr('style', `width : ${width}px!important`);
        }
    });

    // *** 年月選択時の処理
    $(function () {
        $('#select-month-direct').on('change', function() {
            let selectDate = $('#select-month-direct').val();
            let querys = location.search.slice(1).split('&');
            let params = [];
            $.each(querys, function (i, query) {
                if (query.split('=')[0] !== 'date' && query.split('=')[0] !== '') {
                    params.push([query.split('=')[0], query.split('=')[1]]);
                }
            });
            let query = '?';
            $.each(params, function (i, param) {
                query += param[0] + '=' + param[1] + '&';
            });
            query = query.slice(0, -1);
            location.href = location.origin + location.pathname + query + '&date=' + selectDate;
        });
    });

    // *** 一括スケジュール入力処理
    $('#open-modal-counselor-schedule').on('click', function () {
        modal('show');
    });
    $('#fill-counselor-schedule').on('click', function(){
        if( ! $('.wdays input:checked').length) {
            alert('曜日を選択してください');
            return false;
        }
        let dateObj = new Date();
        let yesterday = dateObj.setDate(dateObj.getDate() - 1);
        let fill_records = valid_counselor_schedule($('#modal-inner table'));
        if (fill_records) {
            let wdays = [];
            $('#modal-inner input[type="checkbox"]').each(function(i, e){
                if (e.checked) {
                    wdays.push($(e).attr('wday'));
                }
            });
            if (wdays !== []) {
                fill_records = fill_records[0];
                let records = $('.counselor-schedule-record');
                records.each(function(i, record) {
                    // 本日以前ならスキップ
                    if ( yesterday > (new Date($(record).attr('value'))) ) { return true; }

                    // 既に入力されている情報を一度削除（一括登録対象以外を消す為）
                    $(record).find('input[type="checkbox"]').prop('checked', false);
                    $(record).find('select').each(function(i, elm){
                        $(elm).val('default');
                    });

                    // 該当する曜日に一括登録
                    let wday = $(record).find('.wday').attr('wday');
                    if ( wdays.indexOf(wday) > -1) {

                        let checkbox = $(record).find('.checkbox-reserve');
                        checkbox.prop('checked', true);
                        checkbox.triggerHandler('change');

                        let time = '';

                        time = fill_records['startTime'].split(':');
                        $(record).find('.reserve-start-time .time-hour').val(time[0]);
                        $(record).find('.reserve-start-time .time-minute').val(time[1]);

                        time = fill_records['endTime'].split(':');
                        $(record).find('.reserve-end-time .time-hour').val(time[0]);
                        $(record).find('.reserve-end-time .time-minute').val(time[1]);

                        if (fill_records['break1StartTime'] !== '') {
                            time = fill_records['break1StartTime'].split(':');
                            $(record).find('.break1-start-time .time-hour').val(time[0]);
                            $(record).find('.break1-start-time .time-minute').val(time[1]);

                            time = fill_records['break1EndTime'].split(':');
                            $(record).find('.break1-end-time .time-hour').val(time[0]);
                            $(record).find('.break1-end-time .time-minute').val(time[1]);
                        } else {
                            $(record).find('.break1-start-time .time-hour').val('default');
                            $(record).find('.break1-start-time .time-minute').val('default');
                            $(record).find('.break1-end-time .time-hour').val('default');
                            $(record).find('.break1-end-time .time-minute').val('default');
                        }

                        if (fill_records['break2StartTime'] !== '') {
                            time = fill_records['break2StartTime'].split(':');
                            $(record).find('.break2-start-time .time-hour').val(time[0]);
                            $(record).find('.break2-start-time .time-minute').val(time[1]);

                            time = fill_records['break2EndTime'].split(':');
                            $(record).find('.break2-end-time .time-hour').val(time[0]);
                            $(record).find('.break2-end-time .time-minute').val(time[1]);
                        } else {
                            $(record).find('.break2-start-time .time-hour').val('default');
                            $(record).find('.break2-start-time .time-minute').val('default');
                            $(record).find('.break2-end-time .time-hour').val('default');
                            $(record).find('.break2-end-time .time-minute').val('default');
                        }
                    }
                });
                modal('hide');
                // 登録
                let update_records = valid_counselor_schedule(records);
                if (update_records) {
                    check_update_schedule(update_records);
                }
            }
        }
    });

    // *** カウンセラースケジュールの更新
    // *** inputから配列の整形とバリデーションチェック (戻り値：配列) ***
    function valid_counselor_schedule(records) {
        let update_records = [];
        let isError = false;
        let currentRecord = $('#is-error').attr('id', '');
        let dateObj = new Date();
        let yesterday = dateObj.setDate(dateObj.getDate() - 1);
        records.each(function (i, record) {
            currentRecord = record;
            if ( yesterday > (new Date($(record).attr('value'))) ) { return true; }

            let checkbox = $(record).find('.checkbox-reserve')[0];
            let flag;
            if (checkbox) {
                flag = checkbox.checked;
            }else{
                flag = true;
            }

            let date = $(record).attr('value');

            let startTimeHour = $(record).find('.reserve-start-time .time-hour').val();
            let startTimeMinute = $(record).find('.reserve-start-time .time-minute').val();
            let startTime = startTimeHour + ':' + startTimeMinute + ':00';
            if (startTime.indexOf('default')>-1) startTime = '';


            let endTimeHour = $(record).find('.reserve-end-time .time-hour').val();
            let endTimeMinute = $(record).find('.reserve-end-time .time-minute').val();
            let endTime = endTimeHour + ':' + endTimeMinute + ':00';
            if (endTime.indexOf('default')>-1) endTime = '';
            if (endTimeHour=='24') endTime='23:55:00';

            let break1StartTime = '';
            if ( $(record).find('.break1-start-time .time-hour').val() ) {
                let break1StartTimeHour = $(record).find('.break1-start-time .time-hour').val();
                let break1StartTimeMinute = $(record).find('.break1-start-time .time-minute').val();
                break1StartTime = break1StartTimeHour + ':' + break1StartTimeMinute + ':00';
                if (break1StartTime.indexOf('default')>-1 || break1StartTime.indexOf('undefined')>-1) break1StartTime = '';
            }

            let break1EndTime = '';
            if ( $(record).find('.break1-end-time .time-hour').val() ) {
                let break1EndTimeHour = $(record).find('.break1-end-time .time-hour').val();
                let break1EndTimeMinute = $(record).find('.break1-end-time .time-minute').val();
                break1EndTime = break1EndTimeHour + ':' + break1EndTimeMinute + ':00';
                if (break1EndTime.indexOf('default')>-1 || break1EndTime.indexOf('undefined')>-1) break1EndTime = '';
                if (break1EndTimeHour=='24') break1EndTime='23:55:00';
            }

            let break2StartTime = '';
            if ( $(record).find('.break2-start-time .time-hour').val() ){
                let break2StartTimeHour = $(record).find('.break2-start-time .time-hour').val();
                let break2StartTimeMinute = $(record).find('.break2-start-time .time-minute').val();
                break2StartTime = break2StartTimeHour + ':' + break2StartTimeMinute + ':00';
                if (break2StartTime.indexOf('default')>-1 || break2StartTime.indexOf('undefined')>-1) break2StartTime = '';
            }

            let break2EndTime = '';
            if ( $(record).find('.break2-end-time .time-hour').val() ){
                let break2EndTimeHour = $(record).find('.break2-end-time .time-hour').val();
                let break2EndTimeMinute = $(record).find('.break2-end-time .time-minute').val();
                break2EndTime = break2EndTimeHour + ':' + break2EndTimeMinute + ':00';
                if (break2EndTime.indexOf('default')>-1 || break2EndTime.indexOf('undefined')>-1) break2EndTime = '';
                if (break2EndTimeHour=='24') break2EndTime='23:55:00';
            }

            let result = {
                flag: flag,
                date: date,
                startTime: startTime,
                endTime: endTime,
                break1StartTime: break1StartTime,
                break1EndTime: break1EndTime,
                break2StartTime: break2StartTime,
                break2EndTime: break2EndTime,
            }

            if (result.flag) {

                if (result.startTime ==='') {
                    alert('開始時刻の入力は必須です。');
                    isError = true; return false;
                }
                if (result.endTime === '') {
                    alert('終了時刻の入力は必須です。');
                    isError = true; return false;
                }
                if (Date.parse('2000/1/1 ' + result.startTime) >= Date.parse('2000/1/1 ' + result.endTime)) {
                    alert('終了時刻は、開始時刻より後を選択してください。');
                    isError = true; return false;
                }

                if ( (result.break1StartTime !== '' && result.break1EndTime === '') || (result.break1StartTime === '' && result.break1EndTime !== '') ) {
                    alert('休憩１を入力する場合は、開始時刻と終了時刻の両方入力が必須です。');
                    isError = true; return false;
                }
                if (result.break1StartTime !== '' && result.break1EndTime !== '') {
                    if (Date.parse('2000/1/1 ' + result.break1StartTime) >= Date.parse('2000/1/1 ' + result.break1EndTime)) {
                        alert('休憩１の終了時刻は、休憩１の開始時刻より後を選択してください。');
                        isError = true; return false;
                    }
                    if (Date.parse('2000/1/1 ' + result.startTime) > Date.parse('2000/1/1 ' + result.break1StartTime) ||
                        Date.parse('2000/1/1 ' + result.endTime)   < Date.parse('2000/1/1 ' + result.break1EndTime)) {
                        alert('休憩１の時間は、スケジュール時間内で設定してください。');
                        isError = true; return false;
                    }
                }

                if ( (result.break2StartTime !== '' && result.break2EndTime === '') || (result.break2StartTime === '' && result.break2EndTime !== '') ) {
                    alert('休憩２を入力する場合は、開始時刻と終了時刻の両方入力が必須です。');
                    isError = true; return false;
                }
                if (result.break2StartTime !== '' && result.break2EndTime !== '') {
                    if (Date.parse('2000/1/1 ' + result.break2StartTime) >= Date.parse('2000/1/1 ' + result.break2EndTime)) {
                        alert('休憩２の終了時刻は、休憩２の開始時刻より後を選択してください。');
                        isError = true; return false;
                    }
                    if (Date.parse('2000/1/1 ' + result.startTime) > Date.parse('2000/1/1 ' + result.break2StartTime) ||
                        Date.parse('2000/1/1 ' + result.endTime)   < Date.parse('2000/1/1 ' + result.break2EndTime)) {
                        alert('休憩２の時間は、スケジュール時間内で設定してください。');
                        isError = true; return false;
                    }
                }

                // バリデーション通過したら配列に追加
                update_records.push(result);
            }
        });


        if (isError) {
            currentRecord.id = 'is-error';
            $(window).scrollTop(($('#is-error').position().top) + 20);
            return false;
        } else {
            return update_records;
        }
    }

    // *** スケジュール登録ボタンクリック
    $('#update_counselor_schedule').on('click', function () {
        let records = $('.counselor-schedule-record');
        let update_records = valid_counselor_schedule(records);
        check_update_schedule(update_records);
    });

    // *** スケジュール更新前のチェック処理
    function check_update_schedule(update_records){
        if (update_records) {
            // 月の初日から末日まで取得
            let now = new Date($('#select-month-direct').attr('date') + '-01');
            let firstDay = new Date(now.getFullYear(), now.getMonth(), 1)
            firstDay = firstDay.getFullYear() + "-" + (firstDay.getMonth() + 1) + "-" + firstDay.getDate(); //文字列にフォーマット
            let lastDay = new Date(now.getFullYear(), now.getMonth() + 1, 0);
            lastDay = lastDay.getFullYear() + "-" + (lastDay.getMonth() + 1) + "-" + lastDay.getDate(); //文字列にフォーマット
            $.ajax({
                type: 'POST',
                url: '/s/check_reservations_overtime',
                data: {
                    salon_id: $('#salon_id').val(),
                    counselor_id: $('#id').val(),
                    start_date: firstDay,
                    end_date: lastDay,
                    records: update_records
                },
                dataType: "json"
            })
                .done(function(result) {
                    console.log(result);
                    if (!result || result.length === 0) {
                        update_counselor_schedule_ajax(update_records);
                    }else{
                        if(!confirm('スケジュール時間外になる予約があります。このままスケジュールを更新しますか？')){
                            return false;
                        }else{
                            update_counselor_schedule_ajax(update_records);
                        }
                    }
                })
                .fail(function(result) {
                    alert(ERROR_MSG);
                });
        }
    }

    // *** カウンセラースケジュールの更新_ajax
    function update_counselor_schedule_ajax(update_records) {
        let path = '/s/update_counselor_schedule';

        $.ajax({
            url: URL + path,
            type: "POST",
            data: {
                id: $("#id").val(),
                salon_id: $("#salon_id").val(),
                date_first: $($('.counselor-schedule-record')[0]).attr('value'),
                date_last: $($('.counselor-schedule-record').last()[0]).attr('value'),
                records: update_records,
            },
            dataType: "json"
        })
            .done(function (data) {
                alert('スケジュール情報を更新しました');
                location.replace(location.origin + location.pathname + location.search);
            })
            .fail(function () {
                alert(ERROR_MSG);
            })
    }

    //############################################################
    // counselor_service
    // カウンセラーのメニュー紐付け画面のチェックボックストグル
    //############################################################
    $(function () {
        let records = $('.counselor-service-record');
        records.each(function (i, record) {

            let checkbox = $($(record).find('input[type="checkbox"]')[0]);

            toggleDisabled($(record), checkbox[0]);

            checkbox.on('change', function (ev) {
                toggleDisabled($(record), ev.target);
            });
        });

        function toggleDisabled(record, checkbox) {
            if (checkbox.checked) {
                record.removeClass('disable');
            } else {
                record.addClass('disable');
            }
        }
    });

    //############################################################
    // // カウンセラーのメニュー紐付け更新
    //############################################################
    $('#update_counselor_service').on('click', function() {
        let update_records = [];
        let records = $('.counselor-service-record');

        records.each(function (i, record) {
            let checkbox = $(record).find('input[type="checkbox"]')[0];
            if(checkbox.checked) {
                let result = {
                    service_id:   $(checkbox).attr('service')
                }
                update_records.push(result);
            }
        });
        update_counselor_service_ajax(update_records);
    });

    //############################################################
    // // カウンセラーのメニュー紐付け更新_ajax
    //############################################################
    function update_counselor_service_ajax(update_records) {
        let path = '/s/update_counselor_service';

        $.ajax({
            url: URL + path,
            type: "POST",
            data: {
                counselor_id: $('#counselor_id').val(),
                salon_id:     $('#salon_id').val(),
                records: update_records,
            },
            dataType: "json"
        })
            .done(function (data) {
                alert('受付可能なメニューを更新しました');
                location.replace(location.origin + location.pathname + location.search);
            })
            .fail(function () {
                alert(ERROR_MSG);
            })
    }

    //############################################################
    // reservation_calendar
    // スクロール時にテーブル上部を固定
    //############################################################
    if ( $('#reservation-calendar-wrapper').length ) {
        const target = document.querySelector('#io-target');
        let weekWrapperHeight = $('.week-wrapper').outerHeight();
        let headerHeight = $('.header').height();
        let changeElements = $('#io-change-element');
        let paddingElements = $('#io-padding');

        const options = {
            root: null,
            rootMargin: 0 - headerHeight + "px",
            threshold: 0
        };
        const callback = function(entry) {
            if (entry[0].isIntersecting) {
                // 画面内に表示されている場合
                $('.week-wrapper').removeClass('d-fixed');
                $(changeElements).removeClass('d-fixed');
                $(paddingElements).css("padding-top", 0);
                $('#table-first-row > *').css("width","");
            } else {
                // 画面外に行った場合
                $('.week-wrapper').addClass('d-fixed');
                $(changeElements).addClass('d-fixed');
                $('.week-wrapper.d-fixed').css("top", headerHeight);
                $(changeElements).css("top", (headerHeight + weekWrapperHeight))
                $(paddingElements).css("padding-top", (weekWrapperHeight + 60) );
                $('#table-first-row > *').css("width",$('.day-wrapper:first-of-type').outerWidth());
            }
        }
        const observer = new IntersectionObserver(callback, options);
        observer.observe(target);
    }

    //############################################################
    // Stripeアカウントの発行と紐付け
    // payment_activation , create_stripe_account
    //############################################################


    //############################################################
    // 売上レポート
    // payment_report
    //############################################################
    if( $('#payment-report-wrapper').length ){
        // *** サロンプルダウン
        $('#select-salon').on('change',function(){
            let salon = $('#select-salon').val();
            let ym = $('#select-ym').val();
            location.replace('/s/payment_report?salon_id='+salon+'&ym='+ym);
        });
        // *** 日付プルダウン
        $('#select-ym').on('change',function(){
            let salon = $('#select-salon').val();
            let ym = $('#select-ym').val();
            location.replace('/s/payment_report?salon_id='+salon+'&ym='+ym);
        });

    }
    //############################################################
    // サロンの各種設定変更ページ
    // settings
    //############################################################
    if( $('#settings-wrapper').length ){
        // *** サロンプルダウン
        $('#select-salon').on('change',function(){
            let salon = $('#select-salon').val();
            location.replace('/s/settings?salon_id='+salon);
        });

        // *** 規約確認_予約
        $('.show-agreement-reservation').on('click',function(){
            $('.modal-content').css('display','none');
            $('#modal-agreement-reservation').css('display','block');
            $('#modal-title').text('ネット予約サービスについて');
            modal('show');
        });

        // *** 規約確認_決済
        $('.show-agreement-payment').on('click',function(){
            $('.modal-content').css('display','none');
            $('#modal-agreement-payment').css('display','block');
            $('#modal-title').text('決済サービスについて');
            modal('show');
        });

        // *** 決済新規登録
        $('#stripe-activation').on('click', function(e){
            $(this).css('pointer-events','none');
            $('.modal-content').css('display','none');
            $('#modal-input-payment').css('display','block');
            $('#button-redirect-stripe').attr('salon', $(this).attr('salon'));
            $('#descriptor').val("");
            $('#descriptor-kana').val("");
            $('#modal-title').text('登録お手続き');
            modal('show');
            $(this).css('pointer-events','auto');
        });
        // 決済手続き再開
        $('#stripe-re-activation').on('click', function(e){
            $(this).css('pointer-events','none');
            $('#descriptor').val("");
            $('#descriptor-kana').val("");
            create_stripe_account($(this), false);
            $(this).css('pointer-events','auto');
        });

        $('#button-redirect-stripe').on('click',function(){
            $(this).css('pointer-events','none');
            create_stripe_account($(this));
        });
        function create_stripe_account(elm, create=true){
            // *** バリデーション
            if (create){
                if ( $('#descriptor').val() == "" ){
                    alert('名義（ローマ字）を入力してください');
                    $(elm).css('pointer-events','auto');
                    return false;
                }
                if ( $('#descriptor').val().match(/[^a-z A-Z]/g) != null ){
                    alert('名義（ローマ字）には、半角アルファベットのみ入力してください。');
                    $(elm).css('pointer-events','auto');
                    return false;
                }
                if ($('#descriptor').val().length > 22){
                    alert('名義（ローマ字）は、22文字以内にしてください。');
                    $(elm).css('pointer-events','auto');
                    return false;
                }
                if ( $('#descriptor-kana').val() == "" ){
                    alert('名義（カナ）を入力してください');
                    $(elm).css('pointer-events','auto');
                    return false;
                }
                if ($('#descriptor-kana').val().match(/[^ァ-ン ー]/g) != null ){
                    alert('名義（カナ）には、全角カタカナのみ入力してください。');
                    $(elm).css('pointer-events','auto');
                    return false;
                }
                if ($('#descriptor-kana').val().length > 22){
                    alert('名義（カナ）は、22文字以内にしてください。');
                    $(elm).css('pointer-events','auto');
                    return false;
                }
            }
            modal('hide');
            loadingModal('show');
            $.ajax({
                url: URL + '/s/create_stripe_account',
                type: "POST",
                data: {
                    salon_id: $(elm).attr('salon'),
                    descriptor: $('#descriptor').val(),
                    descriptorKana: $('#descriptor-kana').val()
                },
                dataType: "json"
            })
                .done(function (data) {
                    if( data['ret'] != ''){
                        location.href = data['ret'];
                        //loadingModal('hide');
                    }else{
                        alert(ERROR_MSG);
                        $(elm).css('pointer-events','auto');
                        loadingModal('hide');
                    }
                })
                .fail(function () {
                    alert(ERROR_MSG);
                    $(elm).css('pointer-events','auto');
                    loadingModal('hide');
                })
        }
        // *** 明細名義の動的バリデーション
        $('#descriptor').on('input',function(e){
            if ($(this).val().length > 22) {
                $(this).val( $(this).val().slice(0,22) );
            }
        });
        $('#descriptor-kana').on('input',function(e){
            if ($(this).val().length > 22) {
                $(this).val( $(this).val().slice(0,22) );
            }
        });

        // *** 決済機能の有効無効切り替え
        $('#toggle-salon-payment').on('click', function(){
            $(this).css('pointer-events','none');
            $.ajax({
                url: URL + '/s/toggle_payment',
                type: "POST",
                data: {salon_id: $('#select-salon').val()},
                dataType: "json"
            })
                .done(function (data) {
                    alert('決済サービスを'+data['message']+'にしました');
                    location.reload();
                })
                .fail(function () {
                    alert(ERROR_MSG);
                    $(this).css('pointer-events','auto');
                })
        });

        // *** 予約機能の有効無効切り替え
        $('#toggle-salon-reservation').on('click', function(){
            $(this).css('pointer-events','none');
            $.ajax({
                url: URL + '/s/toggle_reservation',
                type: "POST",
                data: {salon_id: $('#select-salon').val()},
                dataType: "json"
            })
                .done(function (data) {
                    alert('ネット予約サービスを'+data['message']+'にしました');
                    location.reload();
                })
                .fail(function () {
                    alert(ERROR_MSG);
                    $(this).css('pointer-events','auto');
                })
        });
        // *** 問い合わせ機能の有効無効切り替え
        $('#toggle-salon-inquiry').on('click', function(){
            $(this).css('pointer-events','none');
            $.ajax({
                url: URL + '/s/toggle_inquiry',
                type: "POST",
                data: {salon_id: $('#select-salon').val()},
                dataType: "json"
            })
                .done(function (data) {
                    alert('問い合わせ機能を'+data['message']+'にしました');
                    location.reload();
                })
                .fail(function () {
                    alert(ERROR_MSG);
                    $(this).css('pointer-events','auto');
                })
        });
        // *** zoom連携機能の有効無効切り替え
        $('#toggle-salon-zoom').on('click', function(){
            $(this).css('pointer-events','none');
            $.ajax({
                url: URL + '/s/toggle_zoom',
                type: "POST",
                data: {salon_id: $('#select-salon').val()},
                dataType: "json"
            })
                .done(function (data) {
                    alert('zoom連携機能を'+data['message']+'にしました');
                    location.reload();
                })
                .fail(function () {
                    alert(ERROR_MSG);
                    $(this).css('pointer-events','auto');
                })
        });

        // *** zoomアカウントの確認・編集リンク
        $('#edit-zoom').on('click', function(){
            $('.modal-content').css('display','none');
            $('#modal-edit-zoom').css('display','block');
            $('#modal-title').text('zoomアカウント連携情報');
            modal('show');
        });

        // *** Stripeアカウントの確認・編集リンク
        $('#edit-stripe,.edit-counselor-stripe').on('click', function () {
            $(this).css('pointer-events', 'none');
            loadingModal('show');
            let data = {};
            if ($(this).attr('data-counselor-id')) {
                data = { counselor_id: $(this).attr('data-counselor-id') };
            } else {
                data = { salon_id: $('#select-salon').val() };
            }
            $.ajax({
                url: URL + '/s/generate_stripe_link',
                type: "POST",
                data: data,
                dataType: "json"
            })
                .done(function (data) {
                    location.href = data['ret'];
                })
                .fail(function () {
                    alert(ERROR_MSG);
                    $(this).css('pointer-events', 'auto');
                    loadingModal('hide');
                })
        });

        // *** カウンセラー用のStripeアカウント作成リンク
        $('.regist-counselor-stripe, .edit-counselor-stripe').on('click', function(){
            $(this).css('pointer-events','none');
            loadingModal('show');
            $.ajax({
                url: URL + '/s/create_stripe_account',
                type: "POST",
                data: {
                    counselor_id: $(this).attr('data-counselor-id')
                },
                dataType: "json"
            })
                .done(function (data) {
                    location.href = data['ret'];
                })
                .fail(function () {
                    alert(ERROR_MSG);
                    $(this).css('pointer-events','auto');
                    loadingModal('hide');
                })
        });

        // *** カウンセラー個別入金の有効・無効切り替え
        $('.toggle-separate-payout').on('click', function(){
            $(this).css('pointer-events','none');
            $.ajax({
                url: URL + '/s/toggle_separate_payout',
                type: "POST",
                data: {counselor_id: $(this).attr('data-counselor-id')},
                dataType: "json"
            })
                .done(function (data) {
                    alert(data['name'] + 'の個別入金を' +data['message']+'にしました');
                    location.reload();
                })
                .fail(function () {
                    alert(ERROR_MSG);
                    $(this).css('pointer-events','auto');
                })
        });

    }

    //############################################################
    // トークルームの初期設定
    //############################################################
    // アクションメニューのDOMクローン
        // メニュー表示ボタンイベント
        $('#chat-action-button').on('click', function () {
            modal('show');
        });
        if ( $('#chat-action-menu').length ) {
            // メニューに、ボタン群をクローンする。キャンセルボタンのみ削除する。
            let chatRoomButtons = $('#chat-room-buttons').clone();
            chatRoomButtons.find('#button-cancel-reservation').remove();
            $('#chat-action-menu').append(chatRoomButtons);
        }

    //############################################################
    // twilio web clientのトークン認証、device登録、イベント登録
    // chat_room/reservation
    // 今すぐ電話鑑定のみ
    //############################################################
    let durationInterval;
    if ( $('#call-outgoing-wrapper').length && $('#service-type').val() === 'is-instant-telephone' ){
        let call;
        // # 接続開始
        // twilio device オブジェクトの登録。トークンの認証。
        const device = new Device( $("#token").val() );
        device.register();

        // ## 接続破棄ボタン
        $("#twilio-web-end").on('click',function(){
            device.unregister();
        });

        // ## 通話切断ボタンのイベント
        $("#button-call-cancel").on('click', function(){
            device.disconnectAll();
        });
        $("#button-call-hang-up").on('click', function(){
            // 通話終了確認ダイアログ表示。yesなら切断。
            if ( confirm('通話を終了しますか？') ){
                device.disconnectAll();
            }
        });

        // # イベント関係の登録
        // ## 接続開始時のイベント
        device.on('registered', function(){
            console.log('instants registered');
            // オーディオの設定
            console.log(device.audio);
            // device.audio.disconnect(true);
            // device.audio.incoming(true);
            // device.audio.outgoing(true);
        });

        // ## 接続破棄時のイベント
        device.on('unregistered', () => {
            console.log('instants closed');
        });

        // ## 着信時のイベント
        device.on('incoming', incomingCall => {
            console.log('call incoming');
            call = incomingCall;

            // 自動応答
            call.accept();

            // # 通話応答時のイベントを設定
            call.on('accept', acceptCall => {
                $('#call-outgoing-wrapper').css('display', 'none');
                $('#call-accept-wrapper').css('display', 'flex');
                console.log('call accepted');
                // ActionCableのソケットを切断するイベントをトリガー
                try {$('#event-caller-ws-disconnect').click();}catch (e) {console.log(e);}
            });

            // # 通話終了時のイベントを設定
            call.on('disconnect', disconnectCall => {
                console.log('call disconnected');
                // 通話画面を閉じる
                $('#call-accept-wrapper').css('display', 'none');
                // 通話時間タイマーの初期化
                clearInterval(durationInterval);
                // ActionCableのソケットに再接続するイベントをトリガー
                try {$('#event-caller-ws-connect').click();}catch (e) {console.log(e);}
            });

            // # エラー時のイベント
            call.on('error', (error) => {
                alert('お使いのブラウザでマイクの設定が有効になっていない可能性があります。\n' + 'ブラウザの環境設定をご確認ください。');
                console.log('[エラー] : ', error);
                // ActionCableのソケットに再接続するイベントをトリガー
                try {$('#event-caller-ws-connect').click();}catch (e) {console.log(e);}
            });
        });
    }

    // # 発信処理
    // #messagesのカスタムイベントから呼び出し
    $('.button-call-outgoing').off().on('click', function(){
        $('#messages').trigger('button-call-outgoing');
    });
    $('#messages').on('button-call-outgoing', function(e, caller){
        modal('hide');
        let elm = $(caller);
        elm.css('pointer-events', 'none');
        elm.text('発信中...');
        $.ajax({
            url: "/s/twilio_web_call",
            type: "POST",
            data: {
                room_id: $('#room-id').val(),
                room_secret: $('#room-secret').val()
            },
            dataType: "json"
        })
            .done(function (data) {
                if (data['ret'] == 'success') {
                    console.log('発信成功');
                    if (data['available_time']) {
                        // 通話制限時間が指定されている（プリペイド）なら、カウントダウンタイマーセット
                        setCallDurationTimer(Number(data['available_time']), 'sub');
                    }else{
                        // 通話制限時間が指定されていない（後払い）なら、カウントアップタイマーセット
                        setCallDurationTimer(0, 'add');
                    }
                    elm.css('pointer-events','auto');
                }
                else{
                    console.log('発信失敗');
                    if (data['cause']) {
                        alert(data['cause']);
                    }
                    $(elm).css('pointer-events','auto');
                }
            })
            .fail(function () {
                alert(ERROR_MSG);
                $(elm).css('pointer-events','auto');
            })
        elm.css('pointer-events', 'auto');
        elm.text('発信する');
    });

    // 通話時間のタイマー設定
    function setCallDurationTimer(seconds, mode) {
        durationInterval = setInterval(function () {
            // カウントダウン or カウントアップ の設定に応じて計算
            switch (mode) {
                case 'add':
                    seconds += 1;
                    break;

                case 'sub':
                    if (0 < seconds) {
                        seconds -= 1;
                    }else{
                        seconds = 0;
                        clearInterval(durationInterval);
                    }
                    break;

                default:
                    alert('タイマーの設定に失敗しました。');
            }

            // 秒数を MM:SS に変換して表示
            // ex: 65秒 → 01:05
            let minute = Math.floor(seconds / 60);
            minute = minute < 10 ? '0' + minute : minute;
            let second = seconds % 60;
            second = second < 10 ? '0' + second : second;

            $('#call-duration').text(minute + ":" + second);
        }, 1000);
    }

    //############################################################
    // トークルームの予約完了イベント
    // #messagesのカスタムイベントに設定し、ボタンの動的生成時にクリックイベントを再設定しても呼び出しやすいように。
    //############################################################
    $('.button-complete-reservation').off().on('click', function(){
        $('#messages').trigger('button-complete-reservation', $(this));
    });
    $('#messages').on('button-complete-reservation', function(e, caller){
        if (!confirm('鑑定完了にすると、1日後にトークルームがクローズします。完了してよろしいですか？')) {
            return false;
        }
        modal('hide');
        loadingModal('show');
        let elm = $(caller);
        elm.css('pointer-events', 'none');
        $.ajax({
            url: "/chat_room/reservation_complete",
            type: "POST",
            data: {
                room_id: $('#room-id').val(),
                room_secret: $('#room-secret').val()
            },
            dataType: "json"
        })
            .done(function (data) {
                if (data['ret'] == 'success') {
                    location.reload();
                    elm.css('pointer-events','auto');
                }
                else{
                    alert(data['cause']);
                    $(elm).css('pointer-events','auto');
                    loadingModal('hide');
                }
            })
            .fail(function () {
                alert(ERROR_MSG);
                $(elm).css('pointer-events','auto');
                loadingModal('hide');
            })
    });


    // トークルームの予約キャンセルイベント
    // #messagesのカスタムイベントに設定し、ボタンの動的生成時にクリックイベントを再設定しても呼び出しやすいように。
    $('#button-cancel-reservation').off().on('click', function(){
        $('#messages').trigger('button-cancel-reservation', $(this));
    });
    $('#messages').on('button-cancel-reservation', function(e, caller){
        if (!confirm('トークルームをキャンセルしますか？')) {
            return false;
        }
        loadingModal('show');
        let elm = $(caller);
        elm.css('pointer-events', 'none');
        $.ajax({
            url: "/chat_room/reservation_cancel",
            type: "POST",
            data: {
                room_id: $('#room-id').val(),
                room_secret: $('#room-secret').val()
            },
            dataType: "json"
        })
            .done(function (data) {
                if (data['ret'] == 'success') {
                    location.href="/s/waiting";
                }
                else{
                    alert(data['cause']);
                    $(elm).css('pointer-events','auto');
                    loadingModal('hide');
                }
            })
            .fail(function () {
                alert(ERROR_MSG);
                $(elm).css('pointer-events','auto');
                loadingModal('hide');
            })
    });

    //############################################################
    // トークルームの操作
    // chat_room/reservation
    //############################################################
    // メッセージ入力欄がフォーカスされたら、アクションボタンを非表示にする
    $('#send-chat-text').on('focus', function(){
       $('#chat-action-buttons').addClass('hidden');
    });
    // メッセージ入力欄からフォーカスが外れたら、アクションボタンを表示する
    $('#send-chat-text').on('blur', function(){
        $('#chat-action-buttons').removeClass('hidden');
    });


    // トークルームのタブ切り替えするスライドアニメーション設定
    // チャットタブを表示
    $('#room-contents-tab-show').on('click', function () {
        $('#room-contents-tab-show').addClass('a');
        $('#room-reservation-tab-show').removeClass('a');
        $('#room-contents-wrapper').addClass('active');
        $('#room-reservation-wrapper').removeClass('active');
    });
    // 取引履歴タブを表示
    $('#room-reservation-tab-show').on('click', function () {
        $('#room-contents-tab-show').removeClass('a');
        $('#room-reservation-tab-show').addClass('a');
        $('#room-contents-wrapper').removeClass('active');
        $('#room-reservation-wrapper').addClass('active');
    });

    // 今すぐ電話鑑定の手続き開始イベント
    $('.button-start-reservation-prepare').off().on('click', function() {
        $('#messages').trigger('button-start-reservation-prepare', $(this));
    });
    $('#messages').on('button-start-reservation-prepare', function(e, caller){
        modal('hide');
        let elm = $(caller);
        elm.css('pointer-events', 'none');
        $.ajax({
            url: "/chat_room/start_reservation_prepare",
            type: "POST",
            data: {
                room_id: $('#room-id').val(),
                room_secret: $('#room-secret').val(),
                reservation_id: $('#reservation-id').val()
            }
        })
            .done(function(data){
                // alert('鑑定手続きを開始しました。');
            })
            .fail(function(){
                alert(ERROR_MSG);
                elm.css('pointer-events', 'auto');
            });
    });

    //############################################################
    // トークルームの画像アップロードイベント
    //############################################################
    if ($('#messages').length) {
        // * 画像の選択とバリデーション
        const imageInput = document.getElementById('image-input');
        const imagePreview = document.getElementById('image-preview');
        $('#image-upload-button').on('click', function () {
            imageInput.click();
        });

        imageInput.addEventListener('change', async (event) => {
            loadingModal('show');
            let file = event.target.files[0];
            let fileName = file.name;
            let fileExtension = fileName.substring(fileName.lastIndexOf('.') + 1).toLowerCase();
            // 拡張子がない場合はnoneとしてOKにする
            if (fileName.lastIndexOf('.') < 0) { fileExtension = 'none'; }
            let allowedExtensions = ['none', 'jpg', 'jpeg', 'png', 'gif', 'heic', 'heif', 'tiff', 'tif'];

            //バリデーション
            if (allowedExtensions.indexOf(fileExtension) === -1) {
                alert('無効なファイル形式です。有効な形式は jpg、jpeg、png、gif、heic、heif、tif、tiff です。');
                loadingModal('hide');
                return;
            }
            if (file.size > (50 * 1024 * 1024)) {
                alert('ファイルサイズが大きすぎます。50MB以下のファイルを選択してください。');
                loadingModal('hide');
                return;
            }


            if (fileExtension === 'heic' || fileExtension === 'heif') {
                // HEIC,HEIFの場合、heic2anyを使ってpngに変換する
                file = await heic2any({
                    blob: file,
                    toType: 'image/png',
                });
                if (file) {
                    displayImagePreview(file);
                } else {
                    alert('画像の変換に失敗しました。');
                    loadingModal('hide');
                    return;
                }

            } else if (fileExtension === 'tiff' || fileExtension === 'tif') {
                // tif,tiffの場合、tiff.jsを使ってpngに変換する
                try {
                    file = await convertTiffToPng(file);
                    if (file) {
                        displayImagePreview(file);
                    } else {
                        alert('画像の変換に失敗しました。');
                        loadingModal('hide');
                        return;
                    }
                } catch (error) {
                    alert('画像の変換に失敗しました。');
                    loadingModal('hide');
                    return;
                }

            } else {
                // 通常の画像形式なら、アップした画像をプレビュー表示
                displayImagePreview(file);
            }
            loadingModal('hide');
        });

        // プレビューモーダルの処理
        function displayImagePreview(file) {
            const reader = new FileReader();
            reader.addEventListener('load', (event) => {
                imagePreview.src = event.target.result;
            });
            reader.readAsDataURL(file);
            $('#image-preview-wrapper').css('display', 'flex');
        }

        // * 画像のアップロード
        $('#chat-room-image-upload').off().on('click', function(event) {
            console.log('uploading');
            loadingModal('show');
            $(this).css('pointer-events', 'none');
            $(this).text('送信中...');
            setTimeout(function() {
                $.ajax({
                    url: '/chat_room/image_upload/',
                    type: 'POST',
                    data: {
                        'image': imagePreview.src,
                        'file_name': $('#image-input').val(),
                        'room_id': $('#room-id').val(),
                        'room_secret': $('#room-secret').val()
                    }
                })
                    .done(function(data) {
                        if (data['result'] == 'success') {
                            // inputの値を空にする
                            document.getElementById('image-input').value = '';
                            document.getElementById('image-preview').src = '';
                        } else {
                            alert(data['cause']);
                        }
                    })
                    .fail(function() {
                        alert(ERROR_MSG);
                    })
                $('#image-preview-wrapper').css('display', 'none');
                loadingModal('hide');
                $('#chat-room-image-upload').css('pointer-events', 'auto').text('送信');
            }, 200);
        });

        // * アップロードキャンセル
        $('#chat-room-image-upload-cancel').on('click', function() {
            $('#image-preview-wrapper').css('display', 'none');
            document.getElementById('image-input').value = '';
            document.getElementById('image-preview').src = '';
        });
    }

    // method
    // tiffをpngに変換する
    function convertTiffToPng(file) {
        return new Promise((resolve, reject) => {
            const reader = new FileReader();
            reader.onload = (event) => {
                const arrayBuffer = event.target.result;
                const tiffImage = new Tiff({ buffer: arrayBuffer });
                const canvas = tiffImage.toCanvas();

                canvas.toBlob((blob) => {
                    const convertedFile = new File([blob], file.name, { type: 'image/png' });
                    resolve(convertedFile);
                }, 'image/png');
            };
            reader.onerror = (event) => {
                reject(event.target.error);
            };
            reader.readAsArrayBuffer(file);
        });
    }

    //############################################################
    // 画像ポップアップイベント
    //############################################################
    $('#messages').on('popupImage', function(e, caller){
        $('#popup-image').attr('src', $(caller).attr('src'));
        $('#popup-image-wrapper').css('display', 'flex');
    })
    $('#popup-image-close').on('click', function(){
        $('#popup-image-wrapper').css('display', 'none');
        $('#popup-image').attr('src', "");
    });


    //############################################################
    // カウンセラーの、今すぐ系鑑定の受付ステータスを変更する処理
    //############################################################
    $('.switch-wait').on('click', function(){
        change_reception_status($(this).attr('data-cid'), 'wait');
    });
    $('.switch-leave').on('click', function(){
        change_reception_status($(this).attr('data-cid'), 'leave');
    });
    $('.switch-busy').on('click', function(){
        change_reception_status($(this).attr('data-cid'), 'busy');
    });
    $('.switch-invalid').on('click', function(){
        change_reception_status($(this).attr('data-cid'), 'invalid');
    });

    function change_reception_status(counselorId, status){
        $.ajax({
            url: "/s/change_reception_status",
            type: "POST",
            data: {
                counselor_id: counselorId,
                status: status
            }
        })
            .done(function(data){
                if (data['ret'] == 'success') {
                }
                else{
                    alert(data['cause']);
                }
            })
            .fail(function(){
                alert(ERROR_MSG);
            });
    }

    //############################################################
    // 鑑定ルーム関連
    // waiting
    //############################################################
    if($('#waiting-wrapper').length) {
        $(".waiting_on").fadeIn(800);
        $(".waiting_off").fadeIn(800);

        // 待機中・離席中のトグルボタンイベント
        function switch_init() {
            $(".switch-wait").removeClass('active');
            $(".switch-leave").removeClass('active');
            $(".switch-busy").removeClass('active');
            $(".switch-invalid").removeClass('active');
            $(".waiting_wait_msg").fadeOut(0);
            $(".waiting_leave_msg").fadeOut(0);
            $(".waiting_busy_msg").fadeOut(0);
            $(".waiting_invalid_msg").fadeOut(0);
        }
        $(".switch-wait").on("click", function () {
            switch_init();
            $(".switch-wait").addClass('active');
            $(".waiting_wait_msg").fadeIn(240);
        });
        $(".switch-leave").on("click", function () {
            switch_init();
            $(".switch-leave").addClass('active');
            $(".waiting_leave_msg").fadeIn(240);
        });
        $(".switch-busy").on("click", function () {
            switch_init();
            $(".switch-busy").addClass('active');
            $(".waiting_busy_msg").fadeIn(240);
        });
        $(".switch-invalid").on("click", function () {
            switch_init();
            $(".switch-invalid").addClass('active');
            $(".waiting_invalid_msg").fadeIn(240);
        });
        // カウンセラーリストプルダウンの選択イベント
        $("#waiting_counselors").on("change", function () {
            location.href = "/s/waiting?id=" + $("#waiting_counselors").val();
        });

        // 10秒毎に依頼リストを更新するメソッド
        // 新しい依頼があれば、ページをリロードする
        function check_req() {
            if ( (!location.pathname == '/s/waiting') ) { return; }
            console.log('check_req');
            $.ajax({
                url: URL + "/s/check_req",
                type: "POST",
                data: {
                    counselor: $("#waiting_counselors").val(),
                    last_request: $("#last_request").val(),
                    request_class: $('.room-wrapper').length
                },
                dataType: "json"
            })
                .done(function (data) {
                    if (data.new_messages.length){
                        data.new_messages.forEach((elem, index) => {
                            let notification_title = "";
                            let notification_body = "";
                            if(elem.message_type=="reservation_request_chat" || elem.message_type=="reservation_request_instant_telephone"){
                                notification_title = `${elem.nick_name}様から鑑定依頼がありました;`
                                notification_body  = `${elem.dt};`
                            }else if(elem.message_type=="payment_complete_chat" || elem.message_type=="payment_complete_instant_telephone"){
                                notification_title = `${elem.nick_name}様から決済情報の登録がありました;`
                                notification_body  = `${elem.dt};`
                            }else{
                                notification_title = `${elem.nick_name}様からメッセージがあります;`
                                notification_body  = `${elem.dt} ${elem.t}…;`
                            }

                            Push.create(notification_title, {
                                body: notification_body,
                                icon: "https://salon.myshiru.net/logo/mark.png",
                                timeout: 24000,
                                vibrate: [100, 100, 100],
                                onClick: function (e) {
                                },
                                onShow: function (e) {
                                },
                                onClose: function (e) {
                                },
                                onError: function (e) {
                                }
                            });
                        });
                    }
                    if (data.flg == 1) {
                        setTimeout(function() {
                            location.reload();
                        }, 3000);
                    }
                })
                .fail(function () {
                })
            setTimeout(check_req, 10000);
        }

        // 更新サイクルを開始する
        check_req();

        // Push通知
        let push_status = Push.Permission.get();
        if(push_status == "granted"){
            $("#_notification_permission").fadeIn(0);
        }else if(push_status == "denied"){
            $("#notification_denied").fadeIn(0);
        }else if(push_status == "default"){
            $("#notification_permission").fadeIn(0);
        }

        $("#notification_permission").click(() => {
            $.ajax({
                url: '/s/set_notification',
                type: 'POST',
                data: {
                    counselor: $("#waiting_counselors").val()
                }
            })
                .done(function(data) {
                })
            if (!Push.Permission.has()) {
                Push.Permission.request(() => {
                    $("#_notification_permission").fadeIn(360);
                    $("#notification_permission").fadeOut(0);
                }, () => {
                    $("#notification_denied").fadeIn(360);
                    $("#notification_permission").fadeOut(0);
                });
            }
        });
    }


    //############################################################
    // カウンセラー個人アドレスへのテストメール送信
    //############################################################
    $('#send-test-mail-counselor').on('click', function(e) {
        $(this).css('pointer-events', 'none');
        let address = $('#mail').val();
        let name    = $('#name').val();
        if (name.trim() === '') {
            alert('名前を入力してください');
            $(this).css('pointer-events', 'auto');
            return;
        }
        if (address.trim() === '') {
            alert('メールアドレスを入力してください');
            $(this).css('pointer-events', 'auto');
            return;
        }
        if (!address.match(/.+@.+\..+/)) {
            alert('有効なメールアドレスを入力してください。');
            $(this).css('pointer-events', 'auto');
            return;
        }
        $.ajax({
            url: '/s/send_test_mail_counselor',
            data: {
                "name": name,
                "address": address
            },
            type: 'POST'
        })
            .done(function(data) {
                alert(address + '宛に確認メールを送信しました。ご確認ください。');
                $('#send-test-mail-counselor').css('pointer-events', 'auto');
            })
            .fail(function(){
                alert(ERROR_MSG);
                $('#send-test-mail-counselor').css('pointer-events', 'auto');
            })
    })

    //############################################################
    // モーダル共通処理
    //############################################################
    // *** モーダルの表示切り替え関数
    function modal(mode) {
        let modal = $('#modal-background');
        if ( mode === 'show' ) {
            $('body').css('overflow-y', 'hidden');
            // modal.addClass('is-show');
            modal.fadeIn(200).css('display','flex');
        } else {
            $('body').css('overflow-y','auto');
            // modal.removeClass('is-show');
            modal.fadeOut(200);
        }
    }
    $('#modal-background').on('click', function(e) {
        if (e.eventPhase === 2) modal('hide');
    });
    $('#modal-close-button').on('click', function(e) {
        if (e.eventPhase === 2) modal('hide');
    });

    // *** ローディング用モーダル
    function loadingModal(mode, text='') {
        switch (mode) {
            case 'show':
                if( !$('#loading-modal-background').length ){
                    $('body').append( $(
                        '<div id="loading-modal-background">' +
                        '<div id="loading-modal-spinner"></div><p>'+text+'</p>' +
                        '</div>'
                    ) );
                }
                break;
            case 'hide':
                if( $('#loading-modal-background').length ){
                    $('#loading-modal-background').remove();
                }
                break;
        }
    }

    //############################################################
    // その他共通関数
    //############################################################

    // *** 日時のフォーマット
    function formatDate (date, format) {
        format = format.replace(/yyyy/g, date.getFullYear());
        format = format.replace(/M/g, (date.getMonth() + 1));
        format = format.replace(/d/g, (date.getDate()));
        format = format.replace(/H/g, (date.getHours()));
        format = format.replace(/m/g, (date.getMinutes()));
        format = format.replace(/s/g, (date.getSeconds()));
        format = format.replace(/S/g, (date.getMilliseconds()));
        return format;
    }

    // *** 半角数字のみ入力可能にする動的バリデーション(inputイベントから呼び出す)
    function ValidNumberOnly(event) {
        let value = $(event.currentTarget).val();
        value = value
            .replace(/[０-９]/g, function(s) {
                return String.fromCharCode(s.charCodeAt(0) - 65248);
            })
            .replace(/[^0-9]/g, '');
        $(event.currentTarget).val(value);
    }

    // *** 半角アルファベット＋半角スペースのみ入力可能にする(inputイベントから呼び出す)
    function ValidAlphabetOnly(event) {
        let value = $(event.currentTarget).val();
        value = value
            .replace(/[ａ-ｚ]/g, function(s) {
                return String.fromCharCode(s.charCodeAt(0) - 65248);
            })
            .replace(/[^a-z ]/g, '');
        $(event.currentTarget).val(value);
    }

    // *** 全角カタカナ＋半角スペースのみ入力可能にする(inputイベントから呼び出す)
    function ValidKatakanaOnly(event) {
        let value = $(event.currentTarget).val();
        value = value
            .replace(/[ぁ-ん]/g, function(s) {
                return String.fromCharCode(s.charCodeAt(0) - 65248);
            })
            .replace(/[^ァ-ン ]/g, '');
        $(event.currentTarget).val(value);
    }

    //

    //############################################################
    // ハンバーガーメニュー
    //############################################################
    if ($('.hambager_menu_sp')[0]) {
        $('.hambager_menu_sp').on('click', function () {
            $('.nav-bar')[0].classList.toggle('toggle');
            $(this).find('i').toggleClass('fa fa-lg fa-bars fa fa-times');
        });
    }

});
